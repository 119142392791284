import { Card, Col, Row, Typography } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import NodeCreationForm from '../../components/NodeCreationForm';
import { getCountryNameByCountry, getStateNameByCountryAndStateCode } from '../../data';
import { getDayJsObjectWithFormattedString, getDayJsRangeFromTimeRangeString } from '../../helpers/date.helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications } from '../../helpers/toast.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { nodeService } from '../../services';
import { useLoader } from '../../stores/use-loader';

interface INodeDetailsProps { }

const NodeDetails: React.FunctionComponent<INodeDetailsProps> = props => {
  const { nodeId } = useParams();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const [nodeDetails, setNodeDetails] = React.useState({} as any);

  const loadNodeData = async () => {
    setLoading(true);
    const { data, errors } = await nodeService.getNodeByNodeId(nodeId as string);
    if (_.isEmpty(errors)) {
      //!Data to be fed in the form
      const {
        node_name,
        short_name,
        org_code,
        node_type,
        is_operator_managed,
        operator_id,
        priority,
        // lead_time_in_days,
        // shipping_lead_time_in_days,
        timezone,
        online_orders_pickup_available,
        online_orders_delivery_available,
        is_fulfilment_centre,
        excepts_transfer_order,
        expects_return,
        allow_gift_wrap,
        roles,
        is_package_provided,
        notification_time_in_minutes,
        consume_notification_time_in_non_working_hours,
        inventory_execution_lead_time_in_minutes,
        consume_inventory_execution_lead_time_in_non_working_hours,
        is_local_delivery_available,
        is_local_return_available,
        locality_range_in_km,
        currency_code,
        local_delivery_config,
        pickup_config
      } = data;

      const businessEmailObject = (data.emails || []).filter((email: any) => email.type === 'BUSINESS')[0];
      const business_email = businessEmailObject ? businessEmailObject.email_id : '';
      const phoneNumberObject = data?.phone_numbers?.filter(
        (phone: any) => phone.type === 'MOBILE' || phone.type === 'LANDLINE'
      )[0];
      let {
        number,
        country_code,
        availability = '9:00 AM - 5:00 PM'
      } = phoneNumberObject
          ? phoneNumberObject
          : { country_code: '+91', number: '', availability: '9:00 AM - 5:00 PM' };

      const { endTime, startTime } = getDayJsRangeFromTimeRangeString(availability);
      const phone_availability = [startTime, endTime];
      const phone_number = { number, country_code };
      const delivery_methods = data.delivery_methods?.map((method: any) => method.type);
      const primaryAddress = data.address.primary;
      let countryOnlyForNode = primaryAddress?.country_code === 'CHL' ? 'CL' : data.address.primary.country_code;
      const stateName = getStateNameByCountryAndStateCode(
        getCountryNameByCountry(countryOnlyForNode) as string,
        primaryAddress?.state_code
      );

      const primary = {
        type: primaryAddress.type,
        address_line1: primaryAddress.address_line1,
        address_line2: primaryAddress.address_line2,
        address_line3: primaryAddress.address_line3,
        landmark: primaryAddress.landmark,
        city: primaryAddress.city,
        postal_code: primaryAddress.postal_code,
        coordinates: { latitude: primaryAddress.coordinates.latitude, longitude: primaryAddress.coordinates.longitude },
        //!@TODO - How to get country and state from the map
        country: getCountryNameByCountry(countryOnlyForNode),
        state: stateName
      };

      const other_address = (data.address?.others || []).map(
        ({
          address_line1,
          address_line2,
          address_line3,
          city,
          coordinates,
          country_code,
          landmark,
          postal_code,
          state_code,
          type
        }: {
          type: string;
          address_line2: string;
          address_line3: string;
          address_line1: string;
          landmark: string;
          city: string;
          postal_code: string;
          state_code: string;
          country_code: string;
          coordinates: any;
        }) => {
          return {
            address_line1,
            address_line2,
            address_line3,
            city,
            coordinates,
            country_code,
            country: getCountryNameByCountry(country_code),
            landmark,
            postal_code,
            state: getStateNameByCountryAndStateCode(getCountryNameByCountry(country_code) as string, state_code),
            state_code,
            type
          };
        }
      );

      const holidays = (data.work_contract.holidays || []).map(
        (holiday: { reason: string; start_date: string; end_date: string; start_time: string; end_time: string }) => {
          const { end_date, end_time, reason, start_date, start_time } = holiday;
          return {
            reason,
            date: [
              getDayJsObjectWithFormattedString(start_date, 'YYYY-MM-DD'),
              getDayJsObjectWithFormattedString(end_date, 'YYYY-MM-DD')
            ],
            time: [
              getDayJsObjectWithFormattedString(start_time, 'hh:mm:ss'),
              getDayJsObjectWithFormattedString(end_time, 'hh:mm:ss')
            ]
          };
        }
      );

      const services = data.services.map((service: string) => {
        return {
          service
        };
      });

      const work_week = data.work_contract?.work_week.map(
        (workWeek: {
          day_of_the_week: string;
          shift_start_time: string;
          shift_end_time: string;
          cut_off_time: string;
          capacities: any;
        }) => {
          const { capacities, cut_off_time, day_of_the_week, shift_end_time, shift_start_time } = workWeek;

          const mappedCapacities = (capacities || []).map((capacity: any) => {
            const { capacity_type, capacity_uom, slots = [] } = capacity || {};
            const mappedSlots = slots.map((slot: any) => {
              const {
                capacity,
                default_required_capacity_for_uom_line,
                slot_start_time,
                slot_end_time,
                min_weight_in_kg,
                max_weight_in_kg
              } = slot || {};
              return {
                capacity,
                min_weight_in_kg,
                max_weight_in_kg,
                default_required_capacity_for_uom_line,
                slot_time: [
                  getDayJsObjectWithFormattedString(slot_start_time, 'hh:mm:ss'),
                  getDayJsObjectWithFormattedString(slot_end_time, 'hh:mm:ss')
                ]
              };
            });

            return {
              capacity_type,
              capacity_uom,
              slots: mappedSlots
            };
          });

          return {
            day_of_the_week,
            shift_time: [
              getDayJsObjectWithFormattedString(shift_start_time, 'hh:mm:ss'),
              getDayJsObjectWithFormattedString(shift_end_time, 'hh:mm:ss')
            ],
            cut_off_time: getDayJsObjectWithFormattedString(cut_off_time, 'hh:mm:ss'),

            capacities: mappedCapacities
          };
        }
      );

      const packages = (data?.packages || []).map((p: any) => {
        const { type, name, description, is_dimension_variable, dimension, weight_in_gm, cost } = p;

        return {
          type,
          name,
          description,
          is_dimension_variable,
          ...dimension,
          weight_in_gm,
          // currency: cost?.currency_code,
          cost: ((cost.amount || 0) / (cost.fraction || 1)).toFixed(2)
        };
      });

      const contacts = data?.contacts?.map(
        (contact: {
          type: string;
          organisation: string;
          business_unit: string;
          name: string;
          email_id: string;
          phone_number: { country_code: string; number: string };
          availability: string;
        }) => {
          const {
            type,
            organisation,
            business_unit,
            name,
            email_id,
            availability,
            phone_number: { number, country_code }
          } = contact;

          const { endTime, startTime } = getDayJsRangeFromTimeRangeString(availability);
          return {
            type,
            organisation,
            business_unit,
            name,
            email_id,
            phone_number: { number, country_code },
            phone_availability: [startTime, endTime]
          };
        }
      );

      const local_delivery_postal_codes = (data?.local_delivery_config?.local_delivery_postal_codes || []).map(
        (dataResp: any) => dataResp
      );
      const { local_delivery_policy } = local_delivery_config || {};
      const slab_weight_slab_charge_config = (
        local_delivery_policy?.local_delivery_shipping_charge?.slab_weight_slab_charge_config || []
      ).map((dataResp: any) => dataResp);
      const order_price_slab_charge_config = (
        local_delivery_policy?.local_delivery_shipping_charge?.order_price_slab_charge_config || []
      ).map((dataResp: any) => dataResp);
      const pickup_postal_codes = (pickup_config?.pickup_postal_codes || []).map((dataResp: any) => {
        return {
          postcode: dataResp.postcode,
          is_active: dataResp.is_active
        };
      });

      const getCurrency = () => {
        let shipType = local_delivery_policy?.local_delivery_shipping_charge;
        if (shipType?.shipping_charge_type === 'FLAT') {
          return shipType?.flat_charge_config?.currency;
        } else if (shipType?.shipping_charge_type === 'BASE_WEIGHT') {
          return shipType?.base_weight_charge_config?.base_weight?.cost?.currency;
        } else if (shipType?.shipping_charge_type === 'SLAB_WEIGHT') {
          return shipType?.slab_weight_slab_charge_config[0]?.cost?.currency;
        } else if (shipType?.shipping_charge_type === 'ORDER_PRICE') {
          return shipType?.order_price_slab_charge_config[0]?.cost?.currency;
        }
      };

      let currencyData = getCurrency();

      const transformedData = {
        node_name,
        short_name,
        org_code,
        node_type,
        is_operator_managed,
        operator_id,
        priority,
        timezone,
        business_email,
        phone_number,
        phone_availability,
        online_orders_pickup_available,
        pickup_config,
        online_orders_delivery_available,
        is_fulfilment_centre,
        excepts_transfer_order,
        expects_return,
        allow_gift_wrap,
        delivery_methods,
        roles,
        primary,
        other_address,
        holidays,
        services,
        work_week,
        contacts,
        packages,
        is_package_provided,
        notification_time_in_minutes,
        consume_notification_time_in_non_working_hours,
        inventory_execution_lead_time_in_minutes,
        consume_inventory_execution_lead_time_in_non_working_hours,
        is_local_delivery_available,
        is_local_return_available,
        locality_range_in_km,
        currency: currency_code,
        local_delivery_postal_codes,
        local_delivery_policy,
        local_delivery_lead_time_in_minutes: local_delivery_policy?.local_delivery_lead_time_in_minutes,
        is_cod_available: local_delivery_policy?.is_cod_available,
        currency_CODlimit: currencyData,
        currency_local_CODlimit: local_delivery_policy?.cod_limit?.currency,
        amount: local_delivery_policy?.cod_limit?.amount,
        fraction: local_delivery_policy?.cod_limit?.fraction,
        name: local_delivery_policy?.local_delivery_shipping_charge?.name,
        shipping_charge_type: local_delivery_policy?.local_delivery_shipping_charge?.shipping_charge_type,
        slab_weight_slab_charge_config,
        order_price_slab_charge_config,
        pickup_postal_codes,
        min_volume_in_ccm: local_delivery_policy?.min_volume_in_ccm,
        max_volume_in_ccm: local_delivery_policy?.max_volume_in_ccm,
        min_weight_in_kg: local_delivery_policy?.min_weight_in_kg,
        max_weight_in_kg: local_delivery_policy?.max_weight_in_kg,
        currency_PickUp_CODlimit: pickup_config?.pickup_policy?.cod_limit?.currency,
        pickUpConfigAmount: pickup_config?.pickup_policy?.cod_limit?.amount,
        pickUpConfigFraction: pickup_config?.pickup_policy?.cod_limit?.fraction
      };

      setNodeDetails(transformedData);
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    loadNodeData();
  }, []);

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="pt-">
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Node Details
              </Typography.Title>
            </Col>
          </Row>

          <NodeCreationForm fetchedNodeDetails={nodeDetails} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default NodeDetails;
