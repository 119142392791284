import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { orderService } from '../../services';
import { objectHelpers } from '../../../../helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from '../../../../helpers/lodash';
import CustomPagination from '../../../../components/custom-pagination';
import { useLoader } from '../../../../stores/use-loader';
import TenantInfo from '../../../../components/TenantIdInfo';
import {
  formatDateTime,
  resetISOTimeStampTimeToZeroIST,
  resetISOTimeStampTimeToZeroISTCurrent,
} from '../../../../helpers/date.helpers';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';

const TOOrderStatusList = [
  { label: 'Draft Order', value: 'DRAFT_ORDER' },
  { label: 'Created', value: 'CREATED' },
  { label: 'In Negotiation', value: 'IN_NEGOTIATION' },
  { label: 'Backorder From IMS', value: 'BACKORDER_FROM_IMS' },
  { label: 'Order Line Hold', value: 'ORDER_LINE_HOLD' },
  { label: 'Tag Hold', value: 'TAG_HOLD' },
  { label: 'Awaiting Release', value: 'AWAITING_RELEASE' },
  { label: 'Released', value: 'RELEASED' },
  { label: 'Pickup Processing', value: 'PICKUP_PROCESSING' },
  { label: 'Picklist Generated', value: 'PICKLIST_GENERATED' },
  { label: 'Backorder From Node', value: 'BACKORDER_FROM_NODE' },
  { label: 'Partially Pick', value: 'PARTIALLY_PICK' },
  { label: 'Pick', value: 'PICK' },
  { label: 'Partially Pack', value: 'PARTIALLY_PACK' },
  { label: 'Pack', value: 'PACK' },
  { label: 'Ready For Pickup', value: 'READY_FOR_PICKUP' },
  { label: 'Picked Up', value: 'PICKED_UP' },
  { label: 'Shipment Processing', value: 'SHIPMENT_PROCESSING' },
  { label: 'Partially Shipped', value: 'PARTIALLY_SHIPPED' },
  { label: 'Shipped', value: 'SHIPPED' },
  { label: 'In Transit', value: 'IN_TRANSIT' },
  { label: 'Received', value: 'RECEIVED' }
];

interface Order {
  order_number: string;
  status_description: string;
  order_start_trans_datetime: string;
  parent_fo_number: string;
  fo_number: string;
  node_name: string;
  destination_node_name: string;
  order_type: string,
  address: {
    address_info: {
      address_line1: string;
    };
  }[];
  fulfilment_info: any;
  cart_id: string;
  item_list: {
    sku_id: string;
    quantity: {
      number_of_units: number;
    };
    original_quantity: {
      number_of_units: number;
    }
    tags: any;
    receivedQty?: string;
    cancelQty?: string;
    shipDate?: string;
    receiptDate?: string;
    status_description?: string;
  }[];
}

interface TOResponseData {
  order_list: Order[];
  page_info: any;
}

const TransferOrder: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [TOResponseData, setTOResponseData] = useState<TOResponseData | null>(null);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const navigate = useNavigate();
  const [tranferOrderFilterForm] = Form.useForm();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  useEffect(() => {
    const parent_fo_number = searchParams.get('parent_fo_number');
    if (parent_fo_number) {
      tranferOrderFilterForm.setFieldsValue({
        parent_fo_number: parent_fo_number
      });
    }
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const searchParamObj = objectHelpers.convertQueryStringToObj(searchParams.toString());
      const { parent_fo_number } = searchParamObj;
      let params: any = {};
      const filterForm = transformFilterData();
      params = {
        ...params,
        order_type: 'TRANSFER',
        fulfillment_type: 'TRANSFER',
        offset: (pageControl?.currentPage - 1) * pageControl?.pageSize,
        limit: pageControl?.pageSize,
        ...filterForm
      };
      setSearchParams(params);

      const { data, errors } = await orderService.fetchToList(params);
      if (_.isEmpty(errors)) {
        setTOResponseData(data);
      } else {
        displayErrorNotifications(errors);
      }
      setLoading(false);
    } catch (error: any) {
      displayErrorNotifications(error);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageControl) fetchInitialData();
  }, [pageControl]);


  const columns = [
    { title: 'Transfer Order', dataIndex: 'fo_number', key: 'fo_number' },
    { title: 'Status', dataIndex: 'status_description', key: 'status_description' },
    { title: 'Order Date', dataIndex: 'order_start_trans_datetime', key: 'order_start_trans_datetime' },
    { title: 'Parent FO Number', dataIndex: 'parent_fo_number', key: 'parent_fo_number' },
    { title: 'Source Node', dataIndex: 'node_name', key: 'node_name' },
    { title: 'Receiving Node', dataIndex: 'destination_node_name', key: 'destination_node_name' },
    {
      title: 'Delivery Method', dataIndex: 'fulfilment_info.delivery_method', key: 'delivery_method',
      render: (_: any, record: any) => {
        return (
          (record?.fulfilment_info?.delivery_method || ' ').replace(/_/g, ' ')
        );
      }
    },
    { title: 'Customer Order', dataIndex: 'order_number', key: 'order_number' },
    {
      title: 'Estimated Ship Date', dataIndex: 'fulfilment_info.estimated_ship_date', key: 'estimated_ship_date',
      render: (_: any, record: any) => {
        return (
          formatDateTime(
            record.fulfilment_info?.fulfillment_dates?.find((date: any) => date.date_type === 'estimated-shipping-date')
              ?.date_time_stamp
          ) || 'N/A'
        );
      }
    },
    {
      title: 'Estimated Receipt Date', dataIndex: 'fulfilment_info.estimated_receipt_date', key: 'estimated_receipt_date',
      render: (_: any, record: any) => {
        return (
          formatDateTime(
            record?.fulfilment_info?.fulfillment_dates?.find((date: any) => date.date_type === 'promise-delivery-date')
              ?.date_time_stamp
          ) || 'N/A'
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Order) => (
        <Button type="link" onClick={() => navigate(`/release-order?fo_number=${record.fo_number}&order_type=TRANSFER`)}>View ROs</Button>
      )
    }
  ];

  const dataSource = TOResponseData?.order_list.map(order => ({
    key: order.fo_number,
    ...order,
  })) || [];

  const expandedRowRender = (order: Order) => {
    const orderLines = order?.item_list?.map((item, index) => {
      let cancelQty = 0;
      let receivedQty = 0;
      let shipDate: string | undefined;
      let receiptDate: string | undefined;

      item?.tags?.forEach((tag: any) => {
        tag?.statuses?.forEach((status: any) => {
          if (status.status_description === 'CANCELLED') {
            cancelQty += status.quantity.number_of_units;
          }
          if (status.status_description === 'RECEIVED') {
            receivedQty += status.quantity.number_of_units;
          }
          if (status.status_description === 'SHIPPED' && !shipDate) {
            shipDate = status.created_at;
          }
          if (status.status_description === 'RECEIVED' && !receiptDate) {
            receiptDate = status.created_at;
          }
        });
      });

      return {
        key: index,
        sku_id: item.sku_id,
        quantity: item?.quantity?.number_of_units,
        ordered_quantity: item?.original_quantity?.number_of_units,
        receivedQty: receivedQty,
        cancelQty: cancelQty,
        shipDate: formatDateTime(shipDate || '') || '-',
        receiptDate: formatDateTime(receiptDate || '') || '-',
        status_description: (item.status_description || '').replace(/_/g, ' '),
        foNumber: order.fo_number,
        action: 'N/A', // Placeholder for potential actions
        orderType: order?.order_type,
      };
    });

    const orderLineColumns = [
      { title: 'SKU', dataIndex: 'sku_id', key: 'sku_id' },
      { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
      { title: 'Ordered Quantity', dataIndex: 'ordered_quantity', key: 'ordered_quantity' },
      { title: 'Received Qty', dataIndex: 'receivedQty', key: 'receivedQty' },
      { title: 'Cancel Qty', dataIndex: 'cancelQty', key: 'cancelQty' },
      { title: 'Ship Date', dataIndex: 'shipDate', key: 'shipDate' },
      { title: 'Receipt Date', dataIndex: 'receiptDate', key: 'receiptDate' },
      { title: 'Status', dataIndex: 'status_description', key: 'status_description' },
    ];

    return <Table columns={orderLineColumns} dataSource={orderLines} pagination={false} />;
  };

  const transformFilterData = () => {
    let formValues = tranferOrderFilterForm.getFieldsValue();

    if (formValues.date_range) {
      const created_after = resetISOTimeStampTimeToZeroIST(formValues.date_range[0]);
      const created_before = resetISOTimeStampTimeToZeroISTCurrent(formValues.date_range[1]);
      formValues = { ...formValues, created_after, created_before, date_range: undefined };
      delete formValues['date_range'];
    }

    const transformedFormValues = {
      ...formValues,
      status: formValues?.status?.join(',')
    };

    const filteredFormValues = objectHelpers.deleteUndefinedValuesFromObject(transformedFormValues);
    return filteredFormValues;
  };


  return (
    <PrimaryLayout>
      <div className="container mx-auto">
        <Card className="mb-4">
          <Row justify="space-between">
            <Col>
              <p className="text-[20px]">Transfer Order</p>
            </Col>
          </Row>
          <TenantInfo />
        </Card>
        <Card className="mt-4 mb-4">
          <section className=" bg-white  p-6">
            <Form
              layout="vertical"
              form={tranferOrderFilterForm}
              onFinish={() => (pageControl?.currentPage == 1 ? fetchInitialData() : setPageControl(prev => ({ ...prev, currentPage: 1 })))}
            >
              <Row gutter={12}>
                <Col lg={10} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="date_range"
                    label="Date Range"
                    rules={[{ required: false, message: 'Please select order date' }]}
                    required={false}
                  >
                    <DatePicker.RangePicker placeholder={['Start Date', 'End Date']} className="w-full" />
                  </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24} xs={24}>
                  <Form.Item name="parent_fo_number" label=" FO Number">
                    <Input placeholder="Enter FO Number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col lg={5} md={12} sm={24} xs={24}>
                  <Form.Item name="status" label="Order Status">
                    <Select placeholder="Select Status" mode="tags" options={TOOrderStatusList} allowClear showSearch />
                  </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24} xs={24}>
                  <Form.Item name="order_number" label="Order Number">
                    <Input placeholder="Enter Order Number" />
                  </Form.Item>
                </Col>

                <Col lg={3} md={12} sm={24} xs={24} className="flex items-center pt-1">
                  <Button block type="primary" htmlType="submit">
                    <BoldButtonLabel labelText="Apply" />
                  </Button>
                </Col>
                <Col lg={3} md={12} sm={24} xs={24} className="flex items-center pt-1">
                  <Button
                    block
                    onClick={() => {
                      tranferOrderFilterForm.resetFields();
                    }}
                    className="text-red-500 border-red-500"
                  >
                    <BoldButtonLabel labelText="Clear All" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Card>

        <Card>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => true,
            }}
            dataSource={dataSource}
            pagination={{
              total: TOResponseData?.page_info?.total_pages
                ? TOResponseData?.page_info?.total_pages *
                (parseInt(searchParams.get('limit') as string) || 10)
                : 0,
              showSizeChanger: true,
              pageSizeOptions: ['1', '10', '20', '50', '100'],
              current: pageControl?.currentPage,
              pageSize: pageControl?.pageSize,
              onChange: (currentPage, pageSize) => {
                setPageControl({ currentPage, pageSize });
              }
            }}
            scroll={{
              x: 'max-content',
            }}

          />

        </Card>
      </div>
    </PrimaryLayout >
  );
}

export default TransferOrder;
