export const currencyConverter = (base: string, amount: number) => {};

export const conversionRates: Record<string, any> = {
  INR: {
    USD: 0.012,
    INR: 1,
    CLP: 10.83
  },
  USD: {
    INR: 82.81,
    USD: 1,
    CLP: 798.575
  },
  CLP: {
    INR: 0.092,
    CLP: 1,
    USD: 0.0012
  }
};

export const convertBaseToUserCurrency = (base: string, amount: number, userCurrency: string) => {
  return ((amount || 0) * conversionRates[base][userCurrency]).toFixed(2);
};

export const formatPriceToLocaleCurrency = ({
  amount,
  baseCurrency,
  userCurrency
}: {
  baseCurrency: string;
  userCurrency: string;
  amount: number;
}) => {
  const convertedAmount = (amount || 0) * conversionRates[baseCurrency][userCurrency];

  const formatAmount = convertedAmount < 0 ? 0 : convertedAmount;

  return new Intl.NumberFormat(currencyToIntlLocaleMap[userCurrency] || 'en-US', {
    style: 'currency',
    currency: userCurrency
  }).format(formatAmount);
};

export const priceHelpers = {
  formatPriceToLocaleCurrency
};

const currencyToIntlLocaleMap: Record<string, string> = {
  INR: 'en-IN',
  CLP: 'es-CL'
};

export const currencies = {
  INR: 'INR',
  USD: 'USD',
  CLP: 'CLP'
};

export const currencySymbolMap: Record<string, string> = {
  USD: '$',
  INR: '₹',
  CLP: '$'
};

export const localeList = [
  { label: '🇨🇱 CHL', value: 'CL' },
  { label: '🇮🇳 IND', value: 'IN' }
];

export const localeEjjeList = [
  // { label: '🇨🇱 CHL', value: 'CL' },
  // { label: '🇮🇳 IND', value: 'IN' }
  { label: 'EN', value: 'IN' },
  { label: 'ES', value: 'CL' }
];

export const localeToIntlLocaleMap: Record<string, string> = {
  IND: 'en-IN',
  CLP: 'es-CL'
};

export const currencyList = [
  { label: '🇮🇳 INR', value: 'INR', country: 'India' },
  { label: '🇨🇱 CLP', value: 'CLP', country: 'Chile' },
  { label: '🇺🇸 USD', value: 'USD', country: 'USA' }
];

export const currencyListWithCode = [
  { label: '🇮🇳 INR', value: 'INR', country: 'India', code: 'IN' },
  { label: '🇨🇱 CLP', value: 'CLP', country: 'Chile', code: 'CL' },
  { label: '🇺🇸 USD', value: 'USD', country: 'USA', code: 'US' }
];

export const timeZonesWithCode = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India', code: 'IN' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA', code: 'US' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile', code: 'CL' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile', code: 'CL' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe', code: 'US' }
];

export const localeCurrencyMap: Record<string, string> = {
  IND: 'INR',
  CHL: 'CLP',
  CL: 'CLP',
  IN: 'INR'
};

export const phoneCountryCodeList = [
  { label: '🇮🇳 (+91)', value: '+91', title: 'India (+91)' },
  { label: '🇨🇱 (+56)', value: '+56', title: 'Chile (+56)' }
];

export const countryList = [
  { label: '🇮🇳 India', value: 'India', code: 'IN' },
  { label: '🇨🇱 Chile', value: 'Chile', code: 'CL' }
];

export const timeZones = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India', code: 'IN' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA', code: 'USA' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile', code: 'CL' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile', code: 'CL' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe', code: 'ERP' } //code are used in Promotion modifify base on that
];

export const promotionTypes = [
  { label: 'Sub Total Discount Promo', value: 'SUB_TTL_DIS' },
  { label: 'Volume Discount Promo', value: 'VOLUME_DISC' },
  { label: 'Bundle Discount Promo', value: 'BUNDLE_DISC' },
  { label: 'Shipping Discount Promo', value: 'SHIPPING_DISC' },
  { label: 'BXGY Promotion', value: 'BXGY' }
];

export const appliesToClassForSubTotal = [
  { label: 'Order Total', value: 'ORDER_TOTAL' },
  { label: 'Line Items', value: 'LINE_ITEMS' }
];

export const appliesToClassForAll = [
  { label: 'Order Total', value: 'ORDER_TOTAL' },
  { label: 'Line Items', value: 'LINE_ITEMS' },
  { label: 'Shipping', value: 'SHIPPING' },
  { label: 'Loyalty program', value: 'LOYT_POINTS' },
  { label: 'Payments', value: 'PAYMENTS' }
];

export const userTypeSelection = [
  { label: 'Anonymous user', value: 'ANONYMOUS' },
  { label: 'Registered user', value: 'REGISTERED_USER' }
];

export const buyerSelection = [
  { label: 'Everyone', value: 'ALL' },
  { label: 'Specific', value: 'SPECIFIC' }
];

export const tireRestraction = [
  { label: 'Everyone', value: 'EVERYONE' },
  { label: 'Standard', value: 'Standard' },
  { label: 'Gold', value: 'Gold' },
  { label: 'Silver', value: 'Silver' },
  { label: 'Bronze', value: 'Bronze' }
];

export const months = [
  { label: 'January', value: 'January', checked: false },
  { label: 'February', value: 'February', checked: false },
  { label: 'March', value: 'March', checked: false },
  { label: 'April', value: 'April', checked: false },
  { label: 'June', value: 'June', checked: false },
  { label: 'July', value: 'July', checked: false },
  { label: 'August', value: 'August', checked: false },
  { label: 'September', value: 'September', checked: false },
  { label: 'October', value: 'October', checked: false },
  { label: 'November', value: 'November', checked: false },
  { label: 'December', value: 'December', checked: false }
];
export const weeks = [
  { label: 'Monday', value: 'Monday', checked: false },
  { label: 'Tuesday', value: 'Tuesday', checked: false },
  { label: 'Wednesday', value: 'Wednesday', checked: false },
  { label: 'Thursday', value: 'Thursday', checked: false },
  { label: 'Friday', value: 'Friday', checked: false },
  { label: 'Saturday', value: 'Saturday', checked: false },
  { label: 'Sunday', value: 'Sunday', checked: false }
];
export const seasons = [
  { label: 'Spring', value: 'Spring', checked: false },
  { label: 'Summer', value: 'Summer', checked: false },
  { label: 'Monsoon', value: 'Monsoon', checked: false },
  { label: 'Winter', value: 'Winter', checked: false }
];
export const special_day_list = [
  { label: 'Birth Day', value: 'birthday', checked: false },
  { label: 'Sign up day', value: 'signupday', checked: false }
];

//!Final Configuration Object for country data
