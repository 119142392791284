import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuProps,
  Row,
  Select,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import CustomPagination from '../../../../components/custom-pagination';
import { convertQueryStringToObj, fileHelpers, objectHelpers } from '../../../../helpers';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { AnalyticsService } from '../../services/Analytics.service';
import { IRfmlistingResp } from './types/rfm.s';
import { CaretDownOutlined, CaretUpOutlined, DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { t } from 'i18next';
import i18n from '../../../../i18n';
import { useTranslation } from 'react-i18next';

interface IRfmProps {}

// const sortOptions = [
//   { label: 'Customer', value: 'customer_id' },
//   { label: 'Total sales', value: 'total_sale' },
//   { label: 'Frequency', value: 'frequency' },
//   { label: 'Most Recent Customer Order Date', value: 'most_recent_customer_order_date' },
//   { label: 'Most Recent Order', value: 'most_recent_order_date' },
//   { label: 'Recency', value: 'recency' },
//   { label: 'Rfm Value', value: 'rfm_value' }
// ];

const RFM: React.FunctionComponent<IRfmProps> = props => {
  const [rfmForm] = Form.useForm();
  // const [viewRfm, setViewRfm] = useState(false);
  // const [rfmResponseModal, setRfmResponseModal] = useState(rfmDummyRespModal);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [rfmlistingResp, setRfmListingResp] = useState({} as IRfmlistingResp);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const { t } = useTranslation();

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const filterOptions = [
    { label: t('customer'), value: 'customer_id' },
    { label: t('rfmCategory'), value: 'rfm_category' }
  ];

  const columns: TableColumnsType<any> = [
    //IRfmTabelEnteries
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('customer')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                clearSearch();
                handleSearch(0, 'customer_id', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                clearSearch();
                handleSearch(0, 'customer_id', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record.customer_id}</>;
      }
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('totalSales')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                clearSearch();
                handleSearch(0, 'total_sale', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                clearSearch();
                handleSearch(0, 'total_sale', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record?.total_sale}</>;
      }
    },
    {
      title: t('avgMonetaryValue'),
      align: 'center',
      render(value, record, index) {
        return <>{record?.avg_monetary_value}</>;
      }
    },
    // {
    //   title: (
    //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //       {t('frequency')}
    //       <div>
    //         <CaretUpOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'frequency', 'asc');
    //           }}
    //         />
    //         <CaretDownOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'frequency', 'desc');
    //           }}
    //         />
    //       </div>
    //     </div>
    //   ),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record.frequency}</>;
    //   }
    // },
    // {
    //   title: (
    //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //       {t('mostRecentCustomerOrderDate')}
    //       <div>
    //         <CaretUpOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'most_recent_customer_order_date', 'asc');
    //           }}
    //         />
    //         <CaretDownOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'most_recent_customer_order_date', 'desc');
    //           }}
    //         />
    //       </div>
    //     </div>
    //   ),
    //   align: 'center',
    //   render(value, record, index) {
    //     let date = record?.most_recent_customer_order_date
    //       ? dayjs(record?.most_recent_customer_order_date).format('DD/MM/YYYY')
    //       : '';
    //     let time = record?.most_recent_customer_order_date
    //       ? dayjs(record?.most_recent_customer_order_date).format('HH:mm:ss')
    //       : '';
    //     return (
    //       <>
    //         {date} {time}
    //       </>
    //     );
    //   }
    // },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('mostRecentOrder')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                clearSearch();
                handleSearch(0, 'most_recent_order_date', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                clearSearch();
                handleSearch(0, 'most_recent_order_date', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        let date = record?.most_recent_order_date ? dayjs(record?.most_recent_order_date).format('DD/MM/YYYY') : '';
        let time = record?.most_recent_order_date ? dayjs(record?.most_recent_order_date).format('HH:mm:ss') : '';
        return (
          <>
            {date} {time}
          </>
        );
      }
    },
    // {
    //   title: (
    //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //       {t('recency')}
    //       <div>
    //         <CaretUpOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'recency', 'asc');
    //           }}
    //         />
    //         <CaretDownOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'recency', 'desc');
    //           }}
    //         />
    //       </div>
    //     </div>
    //   ),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record.recency}</>;
    //   }
    // },
    // {
    //   title: t('rfmValuePoint'),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record.rfm_point}</>;
    //   }
    // },
    {
      title: t('rfmCategory'),
      align: 'center',
      render(value, record, index) {
        const labelMapping: { [key: string]: string } = {
          new_customers: t('new_customers'),
          active_customers: t('active_customers'),
          loyal_customers: t('loyal_customers'),
          potential_churn: t('potential_churn'),
          lost_customers: t('lost_customers'),
          can_not_loose_customers: t('can_not_loose_customers')
        };
        let data = labelMapping[record?.rfm_category] || record?.rfm_category;
        return <>{data}</>;
      }
    }
    // {
    //   title: t('rfmRecency'),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record.rfm_recency}</>;
    //   }
    // },
    // {
    //   title: t('rfmFrequency'),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record.rfm_frequency}</>;
    //   }
    // },
    // {
    //   title: t('rfmMonetary'),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record.rfm_monetary}</>;
    //   }
    // },
    // {
    //   title: (
    //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //       {t('rfmValue')}
    //       <div>
    //         <CaretUpOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'rfm_value', 'asc');
    //           }}
    //         />
    //         <CaretDownOutlined
    //           style={{ marginLeft: 8, cursor: 'pointer' }}
    //           onClick={() => {
    //             clearSearch();
    //             handleSearch(0, 'rfm_value', 'desc');
    //           }}
    //         />
    //       </div>
    //     </div>
    //   ),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <>{record.rfm_value}</>;
    //   }
    // }
  ];

  useEffect(() => {
    setIntialData();
    loadCategoryOptions();
  }, []);

  const handlePageChange = (current_page: number) => {
    setCurrentPage(current_page);
    const offsetValue = current_page - 1;
    handleSearch(offsetValue);
  };

  const loadCategoryOptions = async () => {
    setLoading(true);
    const { data, errors } = await AnalyticsService.getCategoryData();
    if (_.isEmpty(errors)) {
      const labelMapping: { [key: string]: string } = {
        new_customers: 'New Customers',
        active_customers: 'Active Customers',
        loyal_customers: 'Loyal Customers',
        potential_churn: 'Potential Churn',
        lost_customers: 'Lost Customers',
        can_not_loose_customers: 'High Value Low Engagement'
      };

      const outputArray = data
        ? data?.value?.map((item: string) => ({
            label: labelMapping[item] || item,
            value: item
          }))
        : [];
      setCategoryOptions(outputArray);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setIntialData = () => {
    rfmForm.setFieldsValue({
      order_by: 'desc'
    });
    handleSearch(0);
  };

  const handleSearch = async (offset = 0, sortKey = '', sortWay = '') => {
    setLoading(true);
    let formValues = rfmForm.getFieldsValue();
    const sortKeyData = sortKey;
    const sortValue = sortWay;
    const dataObject = {
      [sortValue]: sortKeyData,
      ...formValues
    };
    // delete dataObject.order_by;
    // delete dataObject.sort_by;
    let params = { ...dataObject, offset, limit: 10 };

    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);

    setSearchParams(filteredParams);
    const { data, errors } = await AnalyticsService.getRfmData(filteredParams);
    if (_.isEmpty(errors)) {
      setRfmListingResp(data);
    } else {
      setRfmListingResp(data);
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnReset = () => {
    rfmForm.setFieldsValue({
      filterBy: null,
      filterValue: null
    });
    handleSearch(0);
  };

  const clearSearch = () => {
    rfmForm.resetFields();
  };

  const getReportData = async () => {
    setLoading(true);
    const { data, errors } = await AnalyticsService.getRFMReport();
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'RFM Report', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleMenuClick = async (e: any) => {
    setLoading(true);
    if (e.key === '1') {
      const queryString = searchParams.toString();
      const queryStringObj = convertQueryStringToObj(queryString);
      console.log('queryStringObj', queryStringObj);
      const { data, errors } = await AnalyticsService.getRFMReport(queryStringObj);
      if (_.isEmpty(errors)) {
        fileHelpers.triggerFileDownload({ data, fileName: 'Reward History Report', extension: '.xlsx' });
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const menuItems: MenuProps['items'] = [
    {
      label: t('downloadCurrentPage'),
      key: '1',
      icon: <DownloadOutlined />
    }
  ];

  const menuProps: MenuProps = {
    items: menuItems,
    onClick: handleMenuClick
  };

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={rfmForm} onFinish={() => handleSearch(0)}>
          <Card>
            <Row justify={'space-between'} className="mb-4" gutter={12}>
              <Col xs={12} md={6}>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  {t('customerProfiling')}
                </Typography.Title>
              </Col>
              <Col xs={24} md={6} lg={4}>
                {/* <Button type="primary" onClick={getReportData}>
                  <BoldButtonLabel labelText="Download" />
                </Button> */}
                <Dropdown.Button menu={menuProps} type="primary" onClick={getReportData}>
                  {t('downloadAll')}
                </Dropdown.Button>
              </Col>
            </Row>
            <TenantInfo />
            <section className="mt-4">
              {' '}
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item label={t('searchBy')} name="filterBy">
                    <Select
                      allowClear
                      size="large"
                      onChange={() => rfmForm.setFieldsValue({ filterValue: null })}
                      placeholder={t('searchBy')}
                      options={filterOptions}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item noStyle shouldUpdate>
                    {form => {
                      const { filterBy } = rfmForm.getFieldsValue();
                      return filterBy === 'customer_id' ? (
                        <>
                          <Form.Item
                            label={t('customerName')}
                            name="filterValue"
                            rules={[{ required: true, message: t('fieldCannotBeEmpty') }]}
                          >
                            <Input size="large" placeholder={t('enterCustomerName')} />
                          </Form.Item>
                          <div className="flex gap-2">
                            <div>
                              <Button htmlType="submit" type="primary" size="large">
                                <BoldButtonLabel labelText={t('search')} />
                              </Button>
                            </div>
                            <div>
                              <Button onClick={handleOnReset} size="large">
                                <BoldButtonLabel labelText={t('reset')} />
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : null;
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {form => {
                      const { filterBy } = rfmForm.getFieldsValue();
                      return filterBy === 'rfm_category' ? (
                        <>
                          <Form.Item
                            label={t('rfmCategory')}
                            name={'filterValue'}
                            className="mb-0"
                            rules={[{ required: true, message: t('fieldCannotBeEmpty') }]}
                          >
                            <Select
                              allowClear
                              size="large"
                              placeholder={t('selectRfmCategory')}
                              options={categoryOptions?.map((item: any) => ({ ...item, label: t(item?.label) }))}
                            ></Select>
                          </Form.Item>
                          <div className="flex gap-2">
                            <div className="mt-4">
                              <Button htmlType="submit" type="primary" size="large">
                                <BoldButtonLabel labelText={t('search')} />
                              </Button>
                            </div>
                            <div className="mt-4">
                              <Button onClick={handleOnReset} size="large">
                                <BoldButtonLabel labelText={t('reset')} />
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </section>

            <section className="mt-2">
              {!_.isEmpty(rfmlistingResp?.data) && (
                <Row className="flex flex-row-reverse" gutter={12}>
                  <Col className="flex flex-row-reverse">
                    <div>
                      <CustomPagination
                        currentPage={rfmlistingResp?.page_info?.current_page}
                        totalPages={rfmlistingResp?.page_info?.total_pages}
                        handleNext={handlePageChange}
                        handlePageChange={handlePageChange}
                        handlePrevious={handlePageChange}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </section>
            <section className="mt-2">
              {' '}
              <Table
                loading={false}
                pagination={false}
                bordered
                dataSource={rfmlistingResp?.data || []}
                columns={columns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
          </Card>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default RFM;
