import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import { validationHelpers } from '../../../../helpers';
import React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import ActionButton from '../../../../components/ActionButton';
import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined
} from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import _ from '../../../../helpers/lodash';
import { RcFile } from 'antd/es/upload';
import * as XLSX from 'xlsx';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { useLoader } from '../../../../stores/use-loader';
import { promotionServiceCreate } from '../../services/promotionCreate.service';
import { useNavigate } from 'react-router-dom';
import WarningModal from '../../../../components/WarningModal';
import { getFormattedNumeric2DecimalPointValidatorForInput } from '../../../../helpers/validation.helpers';
import { TableRowSelection } from 'antd/es/table/interface';
import { MAX_LIMIT_PROMO } from '../../../../constants/common-constants';
import { useProducts } from '../../hooks/drop-down-hooks/use-products';
import { AnalyticsService } from '../../../loyalty-engine/services/Analytics.service';

interface IRewardProps {
  promotionForm: any;
  rewardForm?: any;
  bdyData: any;
  handleOnGoBack: (Key: any, bdyData: any) => void;
  promotionResponse?: any;
  handleOnGoBackReward: (bdyData: any) => void;
  creation: boolean;
}

const RewardForm: React.FunctionComponent<IRewardProps> = ({
  handleOnGoBack,
  promotionResponse,
  promotionForm,
  bdyData,
  rewardForm,
  handleOnGoBackReward,
  creation
}) => {
  const navigate = useNavigate();
  // const [rewardForm] = Form.useForm();
  const [updateRewardForm] = Form.useForm();
  const [bundleRewardForm] = Form.useForm();
  const [bxgyRewardForm] = Form.useForm();
  const [volumeRewardForm] = Form.useForm();
  //SubTotal discount
  const [rewardData, setRewardData] = React.useState<any>([]);
  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [importModalVisible, setImportModalVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [editRewardModal, setEditRewardModal] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  // const [warningBackModal, setWarningBackModal] = React.useState<boolean>(false);
  const [rewardSearchData, setRewardSearchData] = React.useState<any>([]);
  const [categorySearchData, setCategorySearchData] = React.useState<any>([]);
  //SubTotal discount
  //Bundle Discount
  const [bundleDiscountData, setBundleDiscountData] = React.useState<any>([]);
  const [bundleDiscountSearchData, setBundleDiscountSearchData] = React.useState<any>([]);
  const [bundleEditModal, setBundleEditModal] = React.useState(false);
  //Volume Discount
  const [volumeProductData, setVolumeProductData] = React.useState<any>([]);
  const [volumeCategorytData, setVolumeCategoryData] = React.useState<any>([]);
  const [volumeEditModal, setVolumeEditModal] = React.useState(false);
  //Shipping Discount
  //other States
  const [categoryProductOptions, setCategoryProductOptions] = React.useState<any>([]);

  //BXGY
  const [importModalGetBXGYVisible, setImportModalGetBXGYVisible] = React.useState(false);
  const [bxgyBuyItems, setBxgyBuyItems] = React.useState<any>([]);
  const [bxgyGetItems, setBxgyGetItems] = React.useState<any>([]);
  const [bxgyCategory, setBxgyCategory] = React.useState<any>([]);
  const [bxgyeditModal, setBxgyEditModal] = React.useState(false);
  const [bxgyeditCategoryModal, setBxgyEditCategoryModal] = React.useState(false);
  //BXGY
  const [pagination, setPagination] = React.useState({
    pageSize: 5,
    showSizeChanger: false,
    showQuickJumper: false
  });

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { debouncedFetchProductDropdownOptions, productDropdownFetching, productDropdownOptions } = useProducts();

  // COLS---------------------------------------------
  const product_columns: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditReward(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteReward(record);
                }}
              >
                <ActionButton action="DELETE" title={''} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const category_columns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditReward(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteReward(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const bundle_Product_Columns: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditVolume_Product_Columns(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteVolumeReward(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const bundle_Percent_Absolute_Columns: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditVolume_Product_Columns(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteVolumeReward(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const bxgy_Buy_items: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton action="CREATE_NEW_VERSION" title="Edit" onClick={() => editBuyItem(record)}></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeletebuyProduct(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const bxgy_Get_items: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editGetItem(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeletegetProduct(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const bxgy_Get_items_Special: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton action="CREATE_NEW_VERSION" title="Edit" onClick={() => {}}></ActionButton>
              <Popconfirm title="Are you sure ?" onConfirm={() => {}}>
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const bxgy_category_columns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editCatItem(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeleteCategory(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const PercentPriceVolumeColumns: TableColumnsType<any> = [
    {
      title: 'Item Id',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Maximum Discount',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.max_discount}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editVolReward(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeleteVolume(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const AbsolutePriceVolumeColumns: TableColumnsType<any> = [
    {
      title: 'Item Id',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editVolReward(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeleteVolume(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const SpecialPriceVolumeColumns: TableColumnsType<any> = [
    {
      title: 'Item Id',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editVolReward(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeleteVolume(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const ExpandVolumeRowColumnPercentage: TableColumnsType<any> = [
    {
      title: 'Minimum Value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_value}</>;
      }
    },
    {
      title: 'Maximum Value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_value}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    }
  ];

  const PercentPriceVolumeCategoryColumns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Maximum Discount',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.max_discount}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editVolReward(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeleteVolume(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const AbsolutePriceVolumeCategoryColumns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Discount Value',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editVolReward(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeleteVolume(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const SpecialPriceVolumeCategoryColumns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => {
                  editVolReward(record);
                }}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  handleOnDeleteVolume(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];
  // COLS---------------------------------------------

  const loadCategoryProductOptions = async () => {
    const { data, errors } = await AnalyticsService.getCategoryProductData();
    if (_.isEmpty(errors)) {
      const formattedData = (data || [])?.map((item: any) => ({
        label: item,
        value: item
      }));
      setCategoryProductOptions(formattedData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getColoumnsForVoumeProducts = () => {
    const { discount_type } = promotionForm;
    if (discount_type === 'PERCENTAGE') {
      return PercentPriceVolumeColumns;
    } else if (discount_type === 'FIXED') {
      return AbsolutePriceVolumeColumns;
    } else {
      return SpecialPriceVolumeColumns;
    }
  };

  const getColoumnsForVoumeCategory = () => {
    const { discount_type } = promotionForm;
    if (discount_type === 'PERCENTAGE') {
      return PercentPriceVolumeCategoryColumns;
    } else if (discount_type === 'FIXED') {
      return AbsolutePriceVolumeCategoryColumns;
    } else {
      return SpecialPriceVolumeCategoryColumns;
    }
  };

  React.useEffect(() => {
    loadIntialForm();
  }, []);

  const loadIntialForm = () => {
    loadCategoryProductOptions();
    const hasRewardDetails = 'reward_details' in bdyData;
    if (hasRewardDetails) {
      loadDataFromExistingData();
    } else {
      if (!_.isEmpty(promotionResponse)) {
        loadDataFromApi();
      }
    }
  };

  const loadDataFromApi = () => {
    const { discount, application_type, promotion_type } = promotionResponse;
    const { apply_discount_on } = promotionForm;
    const { product_reward, discount_value, max_discount, threshold_selection, thresholds, applies_to_price } =
      discount[0];
    if (application_type !== 'ORDER_TOTAL') {
      if (promotion_type === 'SUB_TTL_DIS') {
        if (promotionForm.apply_discount_on === 'PRODUCTS') {
          const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
          setRewardData(flattenedConditions);
        } else {
          const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => {
            let categoryAddingIndex = info?.conditions?.map((item: any, index: any) => {
              let obj = {
                categoryId: index,
                ...item
              };
              return obj;
            });
            return categoryAddingIndex;
            // return info?.conditions;
          });
          setCategoryData(flattenedConditions);
        }
      }
      if (promotion_type === 'BUNDLE_DISC') {
        const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
        setBundleDiscountData(flattenedConditions);
      }
      if (promotion_type === 'SHIPPING_DISC') {
        let getThreshold =
          thresholds?.threshold_list?.map((item: any) => ({
            from_Quantity: item.minimum_value,
            to_Quantity: item.max_limit_set ? undefined : item.maximum_value, // Hide 'to_Quantity' if 'max_limit_set' is true
            discount_Value: item.discount_value,
            max_limit_set: item.max_limit_set
          })) || null;
        rewardForm.setFieldsValue({
          shipping_discount: getThreshold // Set the mapped values here
        });
      }
      if (promotion_type === 'BXGY') {
        if (apply_discount_on === 'PRODUCTS') {
          const rewardOnItems = product_reward?.product_info?.filter(
            (item: any) => item?.selection_type === 'REWARD_ON'
          );
          const rewardedItems = product_reward?.product_info?.filter(
            (item: any) => item?.selection_type === 'REWARDED'
          );
          let rewadOnCondition = rewardOnItems[0].conditions;
          let rewadedCondition = rewardedItems[0].conditions;
          setBxgyBuyItems(rewadOnCondition);
          setBxgyGetItems(rewadedCondition);
          rewardForm.setFieldsValue({
            applies_to_price: applies_to_price,
            operator_selection_buy: rewardOnItems[0].operator,
            operator_selection_get: rewardedItems[0].operator
          });
        }
        if (apply_discount_on === 'CATEGORY') {
          let discountData = discount[0]?.product_reward?.product_info[0];
          const { min_line_items, conditions } = discountData;
          rewardForm.setFieldsValue({
            applies_to_price: applies_to_price,
            min_line_items: min_line_items || 1
          });
          let tableData = conditions?.map((item: any, currentIndex: any) => {
            return {
              ...item,
              index: currentIndex
            };
          });
          setBxgyCategory(tableData);
        }
      }
    }
    if (promotion_type === 'VOLUME_DISC') {
      let conditionData = product_reward?.product_info[0]?.conditions.map((item: any) => {
        let tresholdAvailable = item?.thresholds.length >= 1;
        return {
          ...item,
          thresholds: tresholdAvailable ? item?.thresholds : null
        };
      });
      if (apply_discount_on === 'PRODUCTS') {
        setVolumeProductData(conditionData);
      } else {
        conditionData = conditionData.map((item: any, index: any) => {
          return {
            ...item,
            item_id: index
          };
        });
        setVolumeCategoryData(conditionData);
      }
    }
    const setFormData = {
      discount_value,
      max_discount,
      threshold_selection,
      subtotal_treshold: thresholds?.threshold_list
    };
    rewardForm.setFieldsValue(setFormData);
  };

  const loadDataFromExistingData = () => {
    const { reward_details, application_type } = bdyData;
    const { promotionType, apply_discount_on } = promotionForm;
    if (reward_details.length > 0) {
      const { product_reward, discount_value, max_discount, threshold_selection, applies_to_price } = reward_details[0];
      //populate data to Table
      if (application_type !== 'ORDER_TOTAL') {
        if (promotionType === 'SUB_TTL_DIS') {
          if (promotionForm.apply_discount_on === 'PRODUCTS') {
            const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
            setRewardData(flattenedConditions);
          } else {
            const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => {
              let categoryAddingIndex = info?.conditions?.map((item: any, index: any) => {
                let obj = {
                  categoryId: index,
                  ...item
                };
                return obj;
              });
              return categoryAddingIndex;
            });
            setCategoryData(flattenedConditions);
          }
        }
        if (promotionType === 'BUNDLE_DISC') {
          const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
          setBundleDiscountData(flattenedConditions);
        }
        if (promotionType === 'BXGY') {
          if (apply_discount_on === 'PRODUCTS') {
            const rewardOnItems = product_reward?.product_info?.filter(
              (item: any) => item?.selection_type === 'REWARD_ON'
            );
            const rewardedItems = product_reward?.product_info?.filter(
              (item: any) => item?.selection_type === 'REWARDED'
            );
            let rewadOnCondition = rewardOnItems[0].conditions;
            let rewadedCondition = rewardedItems[0].conditions;
            setBxgyBuyItems(rewadOnCondition);
            setBxgyGetItems(rewadedCondition);
            rewardForm.setFieldsValue({
              operator_selection_buy: rewardOnItems[0].operator,
              operator_selection_get: rewardedItems[0].operator
            });
          }
          if (apply_discount_on === 'CATEGORY') {
            let discountData = product_reward?.product_info[0];
            const { min_line_items, conditions } = discountData;
            rewardForm.setFieldsValue({
              applies_to_price: applies_to_price,
              min_line_items: min_line_items || 1
            });
            let tableData = conditions?.map((item: any, currentIndex: any) => {
              return {
                ...item,
                index: currentIndex
              };
            });
            setBxgyCategory(tableData);
          }
        }
      }
      if (promotionType === 'VOLUME_DISC') {
        let conditionData = product_reward?.product_info[0]?.conditions.map((item: any) => {
          let tresholdAvailable = item?.thresholds.length >= 1;
          return {
            ...item,
            thresholds: tresholdAvailable ? item?.thresholds : null
          };
        });
        if (apply_discount_on === 'PRODUCTS') {
          setVolumeProductData(conditionData);
        } else {
          conditionData = conditionData.map((item: any, index: any) => {
            return {
              ...item,
              item_id: index
            };
          });
          setVolumeCategoryData(conditionData);
        }
      }
      if (!!threshold_selection === false) {
        rewardForm.setFieldsValue({
          threshold_selection: 'NO_THRESHOLD'
        });
      }
      const setFormData = {
        discount_value,
        max_discount
      };
      rewardForm.setFieldsValue(setFormData);
    }
  };

  const editBuyItem = (record: any) => {
    bxgyRewardForm.setFieldsValue(record);
    setBxgyEditModal(true);
  };

  const editGetItem = (record: any) => {
    bxgyRewardForm.setFieldsValue(record);
    setBxgyEditModal(true);
  };

  const editCatItem = (record: any) => {
    bxgyRewardForm.setFieldsValue(record);
    setBxgyEditCategoryModal(true);
  };

  const editVolReward = (record: any) => {
    volumeRewardForm.setFieldsValue(record);
    setVolumeEditModal(true);
  };

  const handleOnAddSubTotalItems = async () => {
    if (promotionForm.apply_discount_on === 'PRODUCTS') {
      const fieldsToValidate: (string | (string | number)[])[] = ['item_id', 'minimum_quantity', 'maximum_quantity'];
      await rewardForm.validateFields(fieldsToValidate);
      addSubTotalItems();
    } else {
      const fieldsToValidate: (string | (string | number)[])[] = ['category', 'minimum_quantity', 'maximum_quantity'];
      await rewardForm.validateFields(fieldsToValidate);
      addCategories();
    }
  };

  const handleOnAddBundleDiscount = async () => {
    let fieldsToValidate: (string | (string | number)[])[] = [];
    const { discount_type } = promotionForm;
    if (discount_type === 'SPL_PRICE') {
      fieldsToValidate = ['item_id', 'minimum_quantity', 'discount_value'];
    }
    if (discount_type === 'PERCENTAGE' || discount_type === 'FIXED') {
      fieldsToValidate = ['item_id', 'minimum_quantity', 'maximum_quantity'];
    }
    await rewardForm.validateFields(fieldsToValidate);
    addVolumeItem();
  };

  const handleOnAddCategory = async () => {
    const fieldsToValidate: (string | (string | number)[])[] = ['category', 'minimum_quantity', 'maximum_quantity'];
    await rewardForm.validateFields(fieldsToValidate);
    addCategories();
  };

  const handleOnEditReward = (record: any) => {
    updateRewardForm.setFieldsValue(record);
    setEditRewardModal(true);
  };

  const handleOnEditVolume_Product_Columns = (record: any) => {
    bundleRewardForm.setFieldsValue(record);
    setBundleEditModal(true);
  };

  const addSubTotalItems = () => {
    const { item_id } = rewardForm.getFieldsValue();
    let isItemAvailable = rewardData?.findIndex((item: any) => item.item_id === item_id);
    if (isItemAvailable !== -1) {
      displayErrorNotifications([{ message: 'Item already Exits' }]);
    } else {
      const data = {
        ...rewardForm.getFieldsValue(),
        identifier_type: 'SKU_ID'
      };
      setRewardData((prev: any) => [...prev, data]);
      rewardForm.setFieldsValue({
        category: undefined,
        item_id: undefined,
        minimum_quantity: 1,
        maximum_quantity: 1
      });
    }
  };

  const addVolumeItem = () => {
    const { item_id } = rewardForm.getFieldsValue();
    const { discount_type } = promotionForm;
    let fieldsToClear: (string | (string | number)[])[] = [];
    let isItemAvailable = bundleDiscountData?.findIndex((item: any) => item.item_id === item_id);
    if (isItemAvailable !== -1) {
      displayErrorNotifications([{ message: 'Item already Exits' }]);
    } else {
      const data = {
        ...rewardForm.getFieldsValue(),
        identifier_type: 'SKU_ID'
      };
      setBundleDiscountData((prev: any) => [...prev, data]);
      if (discount_type === 'SPL_PRICE') {
        fieldsToClear = ['item_id', 'minimum_quantity', 'discount_value'];
      }
      if (discount_type === 'PERCENTAGE' || discount_type === 'FIXED') {
        fieldsToClear = ['item_id', 'minimum_quantity', 'maximum_quantity'];
      }
      rewardForm.resetFields(fieldsToClear);
    }
  };

  const addCategories = () => {
    const data = {
      ...rewardForm.getFieldsValue(),
      identifier_type: 'SKU_ID',
      categoryId: categoryData.length || 0
    };
    setCategoryData((prev: any) => {
      return [...prev, data];
    });
    rewardForm.setFieldsValue({
      category: undefined,
      minimum_quantity: 1,
      maximum_quantity: 1
    });
  };

  const deleteReward = (record: any) => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      const updatedRewardData = rewardData.filter((item: any) => item.item_id !== record.item_id);
      setRewardData(updatedRewardData);
    } else {
      let updatedRewardData = categoryData.filter((item: any) => item.categoryId !== record.categoryId);
      updatedRewardData = updatedRewardData?.map((item: any, index: any) => {
        return {
          ...item,
          categoryId: index
        };
      });
      setCategoryData(updatedRewardData);
    }
  };

  const deleteVolumeReward = (record: any) => {
    const updatedRewardData = bundleDiscountData.filter((item: any) => item.item_id !== record.item_id);
    setBundleDiscountData(updatedRewardData);
  };

  const handleOnDeletebuyProduct = (record: any) => {
    const updatedRewardData = bxgyBuyItems.filter((item: any) => item.item_id !== record.item_id);
    setBxgyBuyItems(updatedRewardData);
  };

  const handleOnDeletegetProduct = (record: any) => {
    const updatedRewardData = bxgyGetItems.filter((item: any) => item.item_id !== record.item_id);
    setBxgyGetItems(updatedRewardData);
  };

  const handleOnDeleteCategory = (record: any) => {
    const updatedRewardData = bxgyCategory.filter((item: any) => item.index !== record.index);
    setBxgyCategory(updatedRewardData);
  };

  const handleOnDeleteVolume = (record: any) => {
    if (promotionForm.apply_discount_on === 'CATEGORY') {
      let updatedRewardData = volumeCategorytData.filter((item: any) => item.item_id !== record.item_id);
      updatedRewardData = updatedRewardData.map((item: any, index: any) => {
        return {
          ...item,
          item_id: index
        };
      });
      setVolumeCategoryData(updatedRewardData);
    } else {
      let updatedRewardData = volumeProductData.filter((item: any) => item.item_id !== record.item_id);
      setVolumeProductData(updatedRewardData);
    }
    displaySuccessNotification({ message: 'Category Removed!' });
  };

  const closePostCodeModal = () => {
    setFileList([]);
    setImportModalVisible(false);
    setImportModalGetBXGYVisible(false);
  };

  const handleOnUpload = () => {
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);
    if (fileList) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            getValidData(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = fileList[0];
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  const handleOnUploadGet = () => {
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);
    if (fileList) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            getValidDataGetBxgy(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = fileList[0];
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  const getValidData = async (excelData: any) => {
    // setLoading(true);
    if (excelData.length > 10000) {
      displayErrorNotifications([{ message: 'Data cannot exced more than ten thousand' }]);
      return;
    } else {
      let bodyData = loadExcelBodyData(excelData);
      if (promotionForm.promotionType === 'SUB_TTL_DIS') {
        if (promotionForm.apply_discount_on === 'PRODUCTS') {
          setRewardData([...rewardData, ...bodyData]);
        } else {
          // Find the last categoryId in categoryData
          const lastCategoryId = categoryData.length
            ? Math.max(...categoryData.map((item: any) => item.categoryId))
            : -1;
          const updatedCategoryData = [
            ...categoryData,
            ...bodyData.map((item: any, index: any) => ({
              categoryId: lastCategoryId + index + 1,
              ...item
            }))
          ];
          setCategoryData(updatedCategoryData);
        }
      }
      if (promotionForm.promotionType === 'BUNDLE_DISC') {
        setBundleDiscountData([...bundleDiscountData, ...bodyData]);
      }
      if (promotionForm.promotionType === 'BXGY') {
        if (promotionForm.apply_discount_on === 'PRODUCTS') {
          setBxgyBuyItems([...bxgyBuyItems, ...bodyData]);
        } else {
          let prevLength = bxgyCategory.length;
          let bulkData = bodyData?.map((item: any, index: any) => {
            return {
              ...item,
              index: prevLength + index
            };
          });
          let combineBXGYCategory = [...bxgyCategory, ...bulkData];
          setBxgyCategory(combineBXGYCategory);
        }
      }
      if (promotionForm.promotionType === 'VOLUME_DISC') {
        if (promotionForm.apply_discount_on === 'PRODUCTS') {
          setVolumeProductData([...volumeProductData, ...bodyData]);
        } else {
          let prevCategoryLength = volumeCategorytData?.length;
          let bulkData = bodyData?.map((item: any, index: any) => {
            return {
              ...item,
              item_id: prevCategoryLength + index
            };
          });
          setVolumeCategoryData((prev: any) => [...prev, ...bulkData]);
        }
      }
      closePostCodeModal();
    }
  };

  const getValidDataGetBxgy = async (excelData: any) => {
    if (excelData.length > 10000) {
      displayErrorNotifications([{ message: 'Data cannot exced more than ten thousand' }]);
      return;
    } else {
      let bodyData = loadExcelBodyData(excelData);
      setBxgyGetItems([...bxgyGetItems, ...bodyData]);
    }
    closePostCodeModal();
  };

  const loadExcelBodyData = (excelData: any) => {
    let allData = excelData.map((item: any) => {
      return item;
    });
    return allData;
  };

  const handleOnUpdateItem = () => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      const { item_id } = updateRewardForm.getFieldsValue();
      const itemIdToUpdate = item_id;
      const updatedData = updateRewardForm.getFieldsValue();
      const CrrupdateData = rewardData.map((item: any) => {
        if (item.item_id === itemIdToUpdate) {
          return updatedData;
        } else {
          return item;
        }
      });
      setRewardData(CrrupdateData);
    } else {
      const { categoryId } = updateRewardForm.getFieldsValue();
      const itemIdToUpdate = categoryId;
      const updatedData = updateRewardForm.getFieldsValue();
      const CrrupdateData = categoryData.map((item: any) => {
        if (item.categoryId === itemIdToUpdate) {
          return updatedData;
        } else {
          return item;
        }
      });
      setCategoryData(CrrupdateData);
    }
    updateRewardForm.resetFields();
    setEditRewardModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnEditVolumePromo = () => {
    const { item_id } = bundleRewardForm.getFieldsValue();
    const itemIdToUpdate = item_id;
    const updatedData = bundleRewardForm.getFieldsValue();
    const CrrupdateData = bundleDiscountData.map((item: any) => {
      if (item.item_id === itemIdToUpdate) {
        return updatedData;
      } else {
        return item;
      }
    });
    setBundleDiscountData(CrrupdateData);
    setBundleEditModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnBxgyProductEdit = () => {
    const { item_id } = bxgyRewardForm.getFieldsValue();
    const itemIdToUpdate = item_id;
    const updatedData = bxgyRewardForm.getFieldsValue();
    const CrrupdateData = bxgyBuyItems.map((item: any) => {
      if (item.item_id === itemIdToUpdate) {
        return updatedData;
      } else {
        return item;
      }
    });
    setBxgyBuyItems(CrrupdateData);
    setBxgyEditModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnBxgyProductGetEdit = () => {
    const { item_id } = bxgyRewardForm.getFieldsValue();
    const itemIdToUpdate = item_id;
    const updatedData = bxgyRewardForm.getFieldsValue();
    const CrrupdateData = bxgyGetItems?.map((item: any) => {
      if (item.item_id === itemIdToUpdate) {
        return updatedData;
      } else {
        return item;
      }
    });
    setBxgyGetItems(CrrupdateData);
    setBxgyEditModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnBxgyCategoryEdit = () => {
    const { index } = bxgyRewardForm.getFieldsValue();
    const itemIdToUpdate = index;
    const updatedData = bxgyRewardForm.getFieldsValue();
    const CrrupdateData = bxgyCategory?.map((item: any) => {
      if (item.index === itemIdToUpdate) {
        return updatedData;
      } else {
        return item;
      }
    });
    setBxgyCategory(CrrupdateData);
    setBxgyEditCategoryModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnVolumeCategoryEdit = () => {
    const { item_id } = volumeRewardForm.getFieldsValue();
    const itemIdToUpdate = item_id;
    const updatedData = volumeRewardForm.getFieldsValue();
    if (promotionForm.apply_discount_on === 'CATEGORY') {
      const CrrupdateData = volumeCategorytData?.map((item: any) => {
        if (item.item_id === itemIdToUpdate) {
          return updatedData;
        } else {
          return item;
        }
      });
      setVolumeCategoryData(CrrupdateData);
    } else {
      const CrrupdateData = volumeProductData?.map((item: any) => {
        if (item.item_id === itemIdToUpdate) {
          return updatedData;
        } else {
          return item;
        }
      });
      setVolumeProductData(CrrupdateData);
    }
    setVolumeEditModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnUpdatePromo = async () => {
    // setLoading(true);
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());
      const fieldToIgnore = ['item_id', 'category', 'minimum_quantity', 'maximum_quantity'];
      // Filter out the field to ignore from the list of all fields
      const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      // Validate all fields except the one to ignore
      await rewardForm.validateFields(fieldsToValidate);
      AddItem();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
    // setLoading(false);
  };

  const handleOnUpdateBundlePromo = async () => {
    // setLoading(true);
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());
      const fieldToIgnore = ['item_id', 'minimum_quantity', 'discount_value', 'maximum_quantity'];
      // Filter out the field to ignore from the list of all fields
      const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      // Validate all fields except the one to ignore
      await rewardForm.validateFields(fieldsToValidate);
      AddItem();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
    // setLoading(false);
  };

  const handleOnUpdateVolumePromo = () => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      SaveHandler();
      // let creationBodyData = bdyData;
      // let rewardData = getNewReward();
      // let combinedBodyData = {
      //   ...creationBodyData,
      //   reward_details: rewardData
      //     ? Object.values(rewardData).map(reward => ({
      //         applies_to_price: reward.applies_to_price,
      //         apply_discount_on: reward.apply_discount_on,
      //         discount_type: reward.discount_type,
      //         product_reward: reward.product_reward,
      //         threshold_selection: reward.threshold_selection,
      //         thresholds: reward.thresholds,
      //         discount_value: reward.discount_value,
      //         max_discount: reward.max_discount
      //       }))
      //     : []
      // };

      // if (!_.isEmpty(promotionResponse)) {
      //   combinedBodyData?.reward_details?.forEach((reward: any) => {
      //     reward.discount_id = promotionResponse?.discount[0]?.discount_id;
      //   });
      //   handleUpdate(combinedBodyData);
      // } else {
      //   handleOnCreate(combinedBodyData);
      // }
    } else {
      SaveHandler();
      // let creationBodyData = bdyData;
      // let rewardData = getNewReward();
      // let combinedBodyData = {
      //   ...creationBodyData,
      //   reward_details: rewardData
      //     ? Object.values(rewardData).map(reward => ({
      //         applies_to_price: reward.applies_to_price,
      //         apply_discount_on: reward.apply_discount_on,
      //         discount_type: reward.discount_type,
      //         product_reward: reward.product_reward,
      //         threshold_selection: reward.threshold_selection,
      //         thresholds: reward.thresholds,
      //         discount_value: reward.discount_value,
      //         max_discount: reward.max_discount
      //       }))
      //     : []
      // };

      // if (!_.isEmpty(promotionResponse)) {
      //   combinedBodyData?.reward_details?.forEach((reward: any) => {
      //     reward.discount_id = promotionResponse?.discount[0]?.discount_id;
      //   });
      //   handleUpdate(combinedBodyData);
      // } else {
      //   handleOnCreate(combinedBodyData);
      // }
      // AddCategory();
    }
  };

  const handleOnUpdateBxgyProduct = async () => {
    try {
      const fieldsToValidate = ['applies_to_price', 'discount_value', 'max_discount', 'min_line_items'];
      await rewardForm.validateFields(fieldsToValidate);
      AddBXGYItem();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
  };

  const handleOnShippingDiscount = async () => {
    const ShippingForm = rewardForm.getFieldsValue();
    const fieldsToValidate: (string | (string | number)[])[] = [];
    ShippingForm?.threshold_selection !== 'NO_THRESHOLD' &&
      ShippingForm?.shipping_discount.forEach((_: any, index: number) => {
        fieldsToValidate.push(['shipping_discount', index, 'from_Quantity']);
        fieldsToValidate.push(['shipping_discount', index, 'to_Quantity']);
        fieldsToValidate.push(['shipping_discount', index, 'discount_Value']);
      });
    await rewardForm.validateFields(fieldsToValidate);
    shippingDiscount();
  };

  const handleOnAddBuyItems = async () => {
    const fieldsToValidate: (string | (string | number)[])[] = [
      'operator_selection_buy',
      'item_id_buy',
      'minimum_quantity_buy'
    ];
    await rewardForm.validateFields(fieldsToValidate);
    AddBuyItems();
  };

  const handleOnAddVolume = async () => {
    const values = rewardForm.getFieldsValue();
    const fieldsToValidate: (string | (string | number)[])[] = [
      'item_id',
      'category',
      'minimum_quantity',
      'maximum_quantity',
      'discount_value',
      'max_discount'
    ];
    const treshold_list = values.thresholds || [];
    treshold_list.forEach((_: any, index: number) => {
      fieldsToValidate.push(['thresholds', index, 'minimum_value']);
      fieldsToValidate.push(['thresholds', index, 'maximum_value']);
      fieldsToValidate.push(['thresholds', index, 'discount_value']);
    });
    await rewardForm.validateFields(fieldsToValidate);
    addVolumeToTable();
  };

  const addVolumeToTable = () => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      const {
        item_id,
        minimum_quantity,
        maximum_quantity,
        max_discount_checkbox,
        max_discount,
        thresholds_available,
        thresholds,
        discount_value
      } = rewardForm.getFieldsValue();
      let data = [
        {
          item_id,
          minimum_quantity,
          maximum_quantity,
          discount_value,
          max_discount: max_discount_checkbox ? MAX_LIMIT_PROMO : max_discount,
          thresholds_available,
          thresholds
        }
      ];
      setVolumeProductData((prev: any) => [...prev, ...data]);
    }
    if (apply_discount_on === 'CATEGORY') {
      const {
        category,
        minimum_quantity,
        maximum_quantity,
        max_discount_checkbox,
        max_discount,
        thresholds_available,
        thresholds,
        discount_value
      } = rewardForm.getFieldsValue();
      let data = [
        {
          category,
          minimum_quantity,
          maximum_quantity,
          discount_value,
          max_discount: max_discount_checkbox ? MAX_LIMIT_PROMO : max_discount,
          thresholds_available,
          thresholds,
          item_id: volumeCategorytData.length
        }
      ];
      setVolumeCategoryData((prev: any) => [...prev, ...data]);
    }
  };

  const AddBuyItems = () => {
    const { item_id_buy, minimum_quantity_buy } = rewardForm.getFieldsValue();
    let data = {
      item_id: item_id_buy,
      minimum_quantity: minimum_quantity_buy,
      identifier_type: 'SKU_ID'
    };
    setBxgyBuyItems((prev: any) => [...prev, data]);
  };

  const handleOnAddBXGYCategory = async () => {
    const fieldsToValidate: (string | (string | number)[])[] = ['category', 'minimum_quantity', 'maximum_quantity'];
    await rewardForm.validateFields(fieldsToValidate);
    AddBXGYCategory();
  };

  const AddBXGYCategory = () => {
    const { category, minimum_quantity, maximum_quantity } = rewardForm.getFieldsValue();
    let data = {
      index: bxgyCategory.length,
      category: category,
      minimum_quantity: minimum_quantity,
      maximum_quantity: maximum_quantity
    };
    setBxgyCategory((prev: any) => [...prev, data]);
  };

  const handleOnAddGetItems = async () => {
    const fieldsToValidate: (string | (string | number)[])[] = [
      'operator_selection_get',
      'item_id_get',
      'minimum_quantity_get',
      'discount_value_special'
    ];
    await rewardForm.validateFields(fieldsToValidate);
    AddGetItems();
  };

  const AddGetItems = () => {
    const { item_id_get, minimum_quantity_get, discount_value_special } = rewardForm.getFieldsValue();
    let data = {
      item_id: item_id_get,
      minimum_quantity: minimum_quantity_get,
      discount_value: discount_value_special || null,
      identifier_type: 'SKU_ID'
    };
    setBxgyGetItems((prev: any) => [...prev, data]);
  };

  const shippingDiscount = () => {
    // setLoading(true);
    SaveHandler();
    // let creationBodyData = bdyData;
    // let rewardData = getNewReward();
    // let combinedBodyData = {
    //   ...creationBodyData,
    //   reward_details: rewardData
    //     ? Object.values(rewardData).map(reward => ({
    //         applies_to_price: reward.applies_to_price,
    //         apply_discount_on: reward.apply_discount_on,
    //         discount_type: reward.discount_type,
    //         product_reward: reward.product_reward,
    //         threshold_selection: reward.threshold_selection,
    //         thresholds: reward.thresholds,
    //         discount_value: reward.discount_value,
    //         max_discount: reward.max_discount
    //       }))
    //     : []
    // };
    // if (!_.isEmpty(promotionResponse)) {
    //   combinedBodyData?.reward_details?.forEach((reward: any) => {
    //     reward.discount_id = promotionResponse?.discount[0]?.discount_id;
    //   });
    //   handleUpdate(combinedBodyData);
    // } else {
    //   handleOnCreate(combinedBodyData);
    // }
  };

  const handleOnUpdateCategoryPromo = async () => {
    // setLoading(true);
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());
      const fieldToIgnore = ['item_id', 'category', 'minimum_quantity', 'maximum_quantity'];
      const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      await rewardForm.validateFields(fieldsToValidate);
      AddItem();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
    }
    // setLoading(false);
  };

  const handleOnUpdateTreshold = async () => {
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());

      await rewardForm.validateFields(allFields);
      AddThreshold();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
    }
  };

  const getNewReward = () => {
    const { promotionType, appliesTo, threshold_selection, subtotal_treshold, discount_type, apply_discount_on } =
      promotionForm;

    const {
      discount_value,
      max_discount,
      applies_to_price,
      operator_selection_buy,
      operator_selection_get,
      min_line_items
    } = rewardForm.getFieldsValue();

    if (promotionType === 'SUB_TTL_DIS' && discount_type === 'PERCENTAGE') {
      let isOrderTotal = appliesTo === 'ORDER_TOTAL';

      const getTresholdData = () => {
        const thresholdData = subtotal_treshold?.map((item: any) => {
          let data;
          if (item?.max_limit_set) {
            data = {
              ...item,
              discount_value: Number(item.discount_value),
              maximum_value: 999999999 // Remove maximum_value if max_limit_set is true
            };
          } else {
            data = {
              ...item,
              discount_value: Number(item.discount_value)
            };
          }
          return data;
        });
        return thresholdData;
      };

      const getConditionList = () => {
        if (apply_discount_on === 'PRODUCTS') {
          let obj = rewardData.map((item: any) => {
            return {
              ...item,
              identifier_type: 'SKU_ID',
              discount_value: discount_value || 0
            };
          });
          return obj;
        }
        if (apply_discount_on === 'CATEGORY') {
          let obj = categoryData.map((item: any) => {
            return {
              discount_value: discount_value || 0,
              max_discount: max_discount || 0,
              category: item?.category,
              minimum_quantity: item?.minimum_quantity || 1,
              maximum_quantity: item?.maximum_quantity || 1,
              identifier_type: 'SKU_ID',
              item_id: item?.categoryId
            };
          });
          return obj;
        }
        return [];
      };
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: isOrderTotal ? threshold_selection : 'NO_THRESHOLD',
          thresholds: isOrderTotal ? (subtotal_treshold ? { threshold_list: getTresholdData() } : null) : null,
          discount_value: discount_value || 0,
          applies_to_price: 'NONE',
          max_discount: max_discount || 0,
          apply_discount_on: isOrderTotal ? 'ORDER' : apply_discount_on,
          product_reward: isOrderTotal
            ? null
            : {
                product_info: [
                  {
                    selection_type: 'BASIC',
                    operator: 'OR',
                    min_line_items: 0,
                    conditions: getConditionList()
                  }
                ]
              }
        }
      ];
      return combData;
    }
    if (promotionType === 'BUNDLE_DISC') {
      const getConditionList = () => {
        let obj = bundleDiscountData?.map((item: any) => {
          return {
            discount_value: discount_value,
            identifier_type: 'SKU_ID',
            item_id: item?.item_id,
            max_discount: max_discount,
            maximum_quantity: item?.maximum_quantity,
            minimum_quantity: item?.minimum_quantity
          };
        });
        return obj;
      };
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: 'NO_THRESHOLD',
          discount_value: discount_value || null,
          applies_to_price: 'NONE',
          max_discount: max_discount || null,
          apply_discount_on: 'PRODUCTS',
          product_reward: {
            product_info: [
              {
                selection_type: 'BUNDLE',
                operator: 'AND',
                conditions: getConditionList()
              }
            ]
          }
        }
      ];
      return combData;
    }
    if (promotionType === 'SHIPPING_DISC') {
      const getTresholdDataShipDisc = () => {
        const thresholdData = (rewardForm?.getFieldsValue()?.shipping_discount || [])?.map((item: any) => {
          let data;
          let limitSet = item?.max_limit_set?.toString().length > 1 ? item.max_limit_set : false;
          // if (item?.max_limit_set) {
          data = {
            minimum_value: Number(item.from_Quantity),
            maximum_value: !limitSet ? Number(item.to_Quantity) : MAX_LIMIT_PROMO,
            discount_value: Number(item.discount_Value),
            max_limit_set: limitSet
          };
          return data;
        });
        return thresholdData;
      };
      let combData = [
        {
          applies_to_price: 'NONE',
          apply_discount_on: 'ORDER',
          discount_type: discount_type,
          discount_value: discount_value || null,
          max_discount: max_discount || null,
          threshold_selection: rewardForm.getFieldsValue().threshold_selection,
          product_reward: null,
          thresholds:
            rewardForm.getFieldsValue().threshold_selection === 'NO_THRESHOLD'
              ? null
              : {
                  threshold_list: getTresholdDataShipDisc()
                }
        }
      ];
      return combData;
    }
    if (promotionType === 'BXGY') {
      if (apply_discount_on === 'PRODUCTS') {
        const getRewardOnCondition = () => {
          let buyCondition = [];
          buyCondition = bxgyBuyItems?.map((item: any) => {
            return {
              identifier_type: 'SKU_ID',
              item_id: item?.item_id,
              minimum_quantity: item?.minimum_quantity,
              maximum_quantity: 0,
              discount_value: 0,
              max_discount: 0,
              price_ratio: 0
            };
          });
          return buyCondition;
        };

        const getRewardedCondition = () => {
          let getCondition = [];
          getCondition = bxgyGetItems?.map((item: any) => {
            return {
              identifier_type: 'SKU_ID',
              item_id: item?.item_id,
              minimum_quantity: item?.minimum_quantity,
              maximum_quantity: 0,
              discount_value: item?.discount_value || 0,
              max_discount: 0,
              price_ratio: 0
            };
          });
          return getCondition;
        };

        let combData = [
          {
            applies_to_price: applies_to_price,
            apply_discount_on: apply_discount_on,
            discount_type: discount_type,
            discount_value: discount_value || null,
            max_discount: max_discount || null,
            product_reward: {
              product_info: [
                {
                  selection_type: 'REWARD_ON',
                  operator: operator_selection_buy,
                  conditions: getRewardOnCondition(),
                  min_line_items: null
                },
                {
                  selection_type: 'REWARDED',
                  operator: operator_selection_get,
                  conditions: getRewardedCondition(),
                  min_line_items: null
                }
              ]
            }
          }
        ];
        return combData;
      }
      if (apply_discount_on === 'CATEGORY') {
        let conditionForCategory = bxgyCategory.map((item: any) => {
          return {
            category: item?.category,
            identifier_type: 'SKU_ID',
            minimum_quantity: item?.minimum_quantity,
            maximum_quantity: item?.maximum_quantity,
            item_id: '',
            max_discount: 0,
            price_ratio: 0,
            discount_value: 0,
            unit_current_price: 0
          };
        });
        let combData = [
          {
            applies_to_price: applies_to_price,
            apply_discount_on: apply_discount_on,
            discount_type: discount_type,
            discount_value: discount_value || null,
            max_discount: max_discount || null,
            minimum_value: 0,
            allow_customer_choice: false,
            cap_value: 0,
            give_any_item_as_reward: true,
            give_same_item_as_reward: false,
            give_same_item_if_exist: false,
            product_reward: {
              product_info: [
                {
                  selection_type: 'REWARD_ON',
                  // operator: operator_selection_buy,
                  conditions: conditionForCategory,
                  min_line_items: min_line_items,
                  operator: 'OR'
                },
                {
                  selection_type: 'REWARDED',
                  // operator: operator_selection_get,
                  conditions: conditionForCategory,
                  min_line_items: min_line_items,
                  operator: 'OR'
                }
              ]
            }
          }
        ];
        return combData;
      }
    }
    if (promotionType === 'VOLUME_DISC') {
      if (apply_discount_on === 'PRODUCTS') {
        const getThreshold = (data: any) => {
          let treshold: any = [];
          treshold = data.map((item: any) => {
            return {
              minimum_value: item?.minimum_value,
              maximum_value: item?.maximum_value,
              max_limit_set: item?.max_limit_set ? item?.max_limit_set : false,
              discount_value: item?.max_limit_set ? MAX_LIMIT_PROMO : item?.discount_value
            };
          });

          return treshold;
        };
        let rewardData = volumeProductData.map((item: any) => {
          return {
            identifier_type: 'SKU_ID',
            item_id: item?.item_id,
            minimum_quantity: item?.minimum_quantity || 0,
            maximum_quantity: item?.maximum_quantity || 0,
            max_discount: item?.max_discount,
            price_ratio: 0.0,
            discount_value: item?.discount_value || 0.0,
            thresholds: item?.thresholds_available || item?.thresholds.length > 0 ? getThreshold(item?.thresholds) : []
          };
        });
        let combData = [
          {
            discount_type: discount_type,
            threshold_selection: 'QUANTITY',
            thresholds: null,
            discount_value: 0.0,
            minimum_value: 0.0,
            cap_value: 0.0,
            max_discount_item_count: 0.0,
            applies_to_price: 'NONE',
            max_discount: MAX_LIMIT_PROMO,
            product_reward: {
              product_info: [
                {
                  selection_type: 'VOLUME',
                  operator: 'OR',
                  min_line_items: null,
                  conditions: rewardData
                }
              ]
            },
            allow_customer_choice: false,
            give_any_item_as_reward: false,
            give_same_item_if_exist: false,
            show_max_items_to_customer: 1,
            give_same_item_as_reward: false,
            selection_type_logical_operator: 'AND',
            apply_discount_on: apply_discount_on
          }
        ];
        return combData;
      } else {
        const getThreshold = (data: any) => {
          let treshold: any = [];
          treshold = data.map((item: any) => {
            return {
              minimum_value: item?.minimum_value,
              maximum_value: item?.maximum_value,
              max_limit_set: item?.max_limit_set ? item?.max_limit_set : false,
              discount_value: item?.max_limit_set ? MAX_LIMIT_PROMO : item?.discount_value
            };
          });

          return treshold;
        };
        let rewardData = volumeCategorytData?.map((item: any) => {
          return {
            identifier_type: 'SKU_ID',
            category: item?.category,
            item_id: 0,
            minimum_quantity: item?.minimum_quantity || 0,
            maximum_quantity: item?.maximum_quantity || 0,
            max_discount: item?.max_discount,
            price_ratio: 0.0,
            discount_value: item?.discount_value || 0.0,
            thresholds: item?.thresholds_available ? getThreshold(item?.thresholds) : []
          };
        });
        let combData = [
          {
            discount_type: discount_type,
            threshold_selection: 'QUANTITY',
            thresholds: null,
            discount_value: 0.0,
            minimum_value: 0.0,
            cap_value: 0.0,
            max_discount_item_count: 0.0,
            applies_to_price: 'NONE',
            max_discount: MAX_LIMIT_PROMO,
            product_reward: {
              product_info: [
                {
                  selection_type: 'VOLUME',
                  operator: 'OR',
                  min_line_items: null,
                  conditions: rewardData
                }
              ]
            },
            allow_customer_choice: false,
            give_any_item_as_reward: false,
            give_same_item_if_exist: false,
            show_max_items_to_customer: 1,
            give_same_item_as_reward: false,
            selection_type_logical_operator: 'AND',
            apply_discount_on: apply_discount_on
          }
        ];
        return combData;
      }
    }
  };

  const AddItem = () => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      AddReward();
    } else {
      AddCategory();
    }
  };

  const AddBXGYItem = () => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      AddBxgyReward();
    } else {
      AddBxgyCategory();
    }
  };

  const getThresholdReward = () => {
    const { appliesTo, discount_type, apply_discount_on } = promotionForm;

    const { discount_value, max_discount, threshold_selection, subtotal_treshold } = rewardForm.getFieldsValue();
    let isOrderTotal = appliesTo === 'ORDER_TOTAL';

    const getTresholdData = () => {
      const thresholdData = subtotal_treshold?.map((item: any) => {
        let data;
        if (item?.max_limit_set) {
          data = {
            ...item,
            discount_value: Number(item.discount_value),
            maximum_value: 999999999 // Remove maximum_value if max_limit_set is true
          };
        } else {
          data = {
            ...item,
            discount_value: Number(item.discount_value)
          };
        }
        return data;
      });
      return thresholdData;
    };

    let combData = [
      {
        discount_type: discount_type,
        threshold_selection: isOrderTotal ? threshold_selection : 'NO_THRESHOLD',
        thresholds: isOrderTotal ? (subtotal_treshold ? { threshold_list: getTresholdData() } : null) : null,
        discount_value: discount_value || 0,
        applies_to_price: 'NONE',
        max_discount: max_discount || 0,
        apply_discount_on: isOrderTotal ? 'ORDER' : apply_discount_on,
        product_reward: isOrderTotal
          ? null
          : {
              product_info: [
                {
                  selection_type: 'BASIC',
                  operator: 'OR',
                  min_line_items: 0,
                  conditions: null
                }
              ]
            }
      }
    ];
    return combData;
  };

  const AddReward = () => {
    // setLoading(true);
    SaveHandler();
    // let creationBodyData = bdyData;
    // let rewardData = getNewReward();
    // let combinedBodyData = {
    //   ...creationBodyData,
    //   reward_details: rewardData
    //     ? Object.values(rewardData).map(reward => ({
    //         applies_to_price: reward.applies_to_price,
    //         apply_discount_on: reward.apply_discount_on,
    //         discount_type: reward.discount_type,
    //         product_reward: reward.product_reward,
    //         threshold_selection: reward.threshold_selection,
    //         thresholds: reward.thresholds,
    //         discount_value: reward.discount_value,
    //         max_discount: reward.max_discount
    //       }))
    //     : []
    // };

    // if (!_.isEmpty(promotionResponse)) {
    //   combinedBodyData?.reward_details?.forEach((reward: any) => {
    //     reward.discount_id = promotionResponse?.discount[0]?.discount_id;
    //   });
    //   handleUpdate(combinedBodyData);
    // } else {
    //   handleOnCreate(combinedBodyData);
    // }
  };

  const AddBxgyReward = () => {
    SaveHandler();
    // let creationBodyData = bdyData;
    // let rewardData = getNewReward();
    // let combinedBodyData = {
    //   ...creationBodyData,
    //   reward_details: rewardData
    //     ? Object.values(rewardData).map(reward => ({
    //         applies_to_price: reward.applies_to_price,
    //         apply_discount_on: reward.apply_discount_on,
    //         discount_type: reward.discount_type,
    //         product_reward: reward.product_reward,
    //         threshold_selection: reward.threshold_selection,
    //         thresholds: reward.thresholds,
    //         discount_value: reward.discount_value,
    //         max_discount: reward.max_discount
    //       }))
    //     : []
    // };
    // if (!_.isEmpty(promotionResponse)) {
    //   combinedBodyData?.reward_details?.forEach((reward: any) => {
    //     reward.discount_id = promotionResponse?.discount[0]?.discount_id;
    //   });
    //   handleUpdate(combinedBodyData);
    // } else {
    //   handleOnCreate(combinedBodyData);
    // }
  };

  const AddBxgyCategory = () => {
    SaveHandler();
    // let creationBodyData = bdyData;
    // let rewardData = getNewReward();
    // let combinedBodyData = {
    //   ...creationBodyData,
    //   reward_details: rewardData
    //     ? Object.values(rewardData).map(reward => ({
    //         applies_to_price: reward.applies_to_price,
    //         apply_discount_on: reward.apply_discount_on,
    //         discount_type: reward.discount_type,
    //         product_reward: reward.product_reward,
    //         threshold_selection: reward.threshold_selection,
    //         thresholds: reward.thresholds,
    //         discount_value: reward.discount_value,
    //         max_discount: reward.max_discount
    //       }))
    //     : []
    // };
    // if (!_.isEmpty(promotionResponse)) {
    //   combinedBodyData?.reward_details?.forEach((reward: any) => {
    //     reward.discount_id = promotionResponse?.discount[0]?.discount_id;
    //   });
    //   handleUpdate(combinedBodyData);
    // } else {
    //   handleOnCreate(combinedBodyData);
    // }
  };

  const AddCategory = () => {
    SaveHandler();
    // let creationBodyData = bdyData;
    // let rewardData = getNewReward();
    // let combinedBodyData = {
    //   ...creationBodyData,
    //   reward_details: rewardData
    //     ? Object.values(rewardData).map(reward => ({
    //         applies_to_price: reward.applies_to_price,
    //         apply_discount_on: reward.apply_discount_on,
    //         discount_type: reward.discount_type,
    //         product_reward: reward.product_reward,
    //         threshold_selection: reward.threshold_selection,
    //         thresholds: reward.thresholds,
    //         discount_value: reward.discount_value,
    //         max_discount: reward.max_discount
    //       }))
    //     : []
    // };

    // if (!_.isEmpty(promotionResponse)) {
    //   combinedBodyData?.reward_details?.forEach((reward: any) => {
    //     reward.discount_id = promotionResponse?.discount[0]?.discount_id;
    //   });
    //   handleUpdate(combinedBodyData);
    // } else {
    //   handleOnCreate(combinedBodyData);
    // }
  };

  const AddThreshold = () => {
    let creationBodyData = bdyData;
    let rewardData = getThresholdReward();
    let combinedBodyData = {
      ...creationBodyData,
      reward_details: rewardData
        ? Object.values(rewardData).map(reward => ({
            applies_to_price: reward.applies_to_price,
            apply_discount_on: reward.apply_discount_on,
            discount_type: reward.discount_type,
            product_reward: reward.product_reward,
            threshold_selection: reward.threshold_selection,
            thresholds: reward.thresholds,
            discount_value: reward.discount_value,
            max_discount: reward.max_discount
          }))
        : []
    };
    if (!_.isEmpty(promotionResponse)) {
      combinedBodyData?.reward_details?.forEach((reward: any) => {
        reward.discount_id = promotionResponse?.discount[0]?.discount_id;
      });
      handleUpdate(combinedBodyData);
    } else {
      handleOnCreate(combinedBodyData);
    }
  };

  const handleUpdate = async (bodyData: any) => {
    setLoading(true);
    const { errors } = await promotionServiceCreate.updatePromotion(bodyData, promotionResponse?.id);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion updated successfully!' });
      navigate(-1);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnCreate = async (bodyData: any) => {
    const { errors } = await promotionServiceCreate.getCreatePromotion(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion created successfully!' });
      navigate(-1);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const onDeleteSelected = () => {
    const { apply_discount_on, promotionType } = promotionForm;
    if (promotionType === 'SUB_TTL_DIS') {
      if (apply_discount_on === 'PRODUCTS') {
        let filter_Data = rewardData?.filter((item: any) => !selectedRowKeys?.includes(item?.item_id));
        setRewardData(filter_Data);
        displaySuccessNotification({ message: 'Selected Items Removed Successfully!!  Please Add New Rewards.' });
      } else {
        let filterData = categoryData?.filter((item: any) => !selectedRowKeys.includes(item.categoryId));
        let reAssignCatId = filterData?.map((item: any, index: any) => {
          return {
            ...item,
            categoryId: index
          };
        });
        setCategoryData(reAssignCatId);
        displaySuccessNotification({ message: 'Selected Items Removed Successfully!!  Please Add New Rewards.' });
      }
    }
    if (promotionType === 'BUNDLE_DISC') {
      let filter_Data = bundleDiscountData?.filter((item: any) => !selectedRowKeys?.includes(item?.item_id));
      setBundleDiscountData(filter_Data);
      displaySuccessNotification({ message: 'Selected Items Removed Successfully!!  Please Add New Rewards.' });
    }
    setSelectedRowKeys([]);
  };

  const goBackSaver = (creationBodyData: any) => {
    let combinedBodyData = {};
    const { appliesTo, promotion_type } = promotionForm;
    if (appliesTo === 'ORDER_TOTAL') {
    } else {
      let rewardData = getNewReward();
      combinedBodyData = {
        ...creationBodyData,
        reward_details: rewardData
          ? Object.values(rewardData).map(reward => ({
              applies_to_price: reward.applies_to_price,
              apply_discount_on: reward.apply_discount_on,
              discount_type: reward.discount_type,
              product_reward: reward.product_reward,
              threshold_selection: reward.threshold_selection,
              thresholds: reward.thresholds,
              discount_value: reward.discount_value,
              max_discount: reward.max_discount
            }))
          : []
      };
    }
    return combinedBodyData;
  };

  const handleOnSearch = () => {
    const { apply_discount_on, promotionType } = promotionForm;
    const { search } = rewardForm.getFieldsValue();
    if (search === '') {
      setRewardSearchData([]);
      setCategorySearchData([]);
      setBundleDiscountSearchData([]);
      return;
    }
    if (promotionType === 'SUB_TTL_DIS') {
      if (apply_discount_on === 'PRODUCTS') {
        let searchData = rewardData?.find((item: any) => item?.item_id === search);
        if (!!searchData) {
          let casualData = [searchData];
          setRewardSearchData(casualData);
        } else {
          setRewardSearchData([]);
          displayErrorNotifications([{ message: 'Item does not exits' }]);
        }
      }
      if (apply_discount_on === 'CATEGORY') {
        let searchData = categoryData?.filter((item: any) => {
          return item?.category === search;
        });
        if (searchData.length >= 1) {
          setCategorySearchData(searchData);
        } else {
          setCategorySearchData([]);
          displayErrorNotifications([{ message: 'Item does not exits' }]);
        }
      }
    }
    if (promotionType === 'BUNDLE_DISC') {
      let searchData = bundleDiscountData?.find((item: any) => item?.item_id === search);
      if (!!searchData) {
        let casualData = [searchData];
        setBundleDiscountSearchData(casualData);
      } else {
        setBundleDiscountSearchData([]);
        displayErrorNotifications([{ message: 'Item does not exits' }]);
      }
    }
  };

  const handleOnClearSearch = () => {
    const { search } = rewardForm.getFieldsValue();
    if (search.length > 0) {
      rewardForm.setFieldsValue({
        search: undefined
      });
      setRewardSearchData([]);
      setCategorySearchData([]);
    }
  };

  const SubTotalScreenRenderer = () => {
    const { appliesTo, apply_discount_on } = promotionForm;

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
    };
    return (
      <>
        {appliesTo === 'LINE_ITEMS' && (
          <>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Discount Value"
                  name={'discount_value'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        // const applyDiscountOn = getFieldValue('apply_discount_on'); // Get the field value dynamically
                        if (apply_discount_on === 'PRODUCTS' && value > 100) {
                          return Promise.reject(
                            new Error('Discount Value cannot exceed 100 when applying to Products.')
                          );
                        }
                        return Promise.resolve();
                      }
                    }),
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    min={1}
                    size="large"
                    placeholder="Discount Value"
                    className="w-full"
                    style={{ borderRadius: '0' }}
                    disabled={getDisableSBTFields()}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Maximum Discount"
                  name={'max_discount'}
                  rules={[
                    { required: false, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Maximum Discount"
                    className="w-full"
                    style={{ borderRadius: '0' }}
                    disabled={getDisableSBTFields()}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <Card>
                <Row gutter={12}>
                  {apply_discount_on === 'PRODUCTS' && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Item Id"
                        name={'item_id'}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          className="w-full"
                          size="large"
                          onSearch={async searchTerm => {
                            if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm);
                          }}
                          filterOption={true}
                          placeholder="Search Item"
                          notFoundContent={productDropdownFetching ? <Spin size="small" /> : <>Search Item</>}
                          options={productDropdownOptions}
                        ></Select>
                      </Form.Item>
                    </Col>
                  )}
                  {apply_discount_on === 'CATEGORY' && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Category"
                        name={'category'}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        {/* <Input size="large" placeholder="Category" /> */}
                        <Select
                          placeholder="Select"
                          allowClear
                          size="large"
                          options={categoryProductOptions || []}
                        ></Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Minimum Quantity"
                      name="minimum_quantity"
                      initialValue={1}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const maxQuantity = getFieldValue('maximum_quantity');
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value !== undefined && maxQuantity !== undefined && value > maxQuantity) {
                              return Promise.reject(new Error('Minimum Quantity cannot exceed Maximum Quantity'));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder="Minimum Quantity"
                        className="w-full"
                        min={1}
                        size="large"
                        style={{ borderRadius: '0' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Maximum Quantity"
                      name="maximum_quantity"
                      initialValue={1}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const minQuantity = getFieldValue('minimum_quantity');
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value !== undefined && minQuantity !== undefined && value < minQuantity) {
                              return Promise.reject(new Error('Maximum Quantity cannot be less than Minimum Quantity'));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder="Maximum Quantity"
                        className="w-full"
                        min={1}
                        size="large"
                        style={{ borderRadius: '0' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button type="primary" size="large" onClick={handleOnAddSubTotalItems}>
                    <BoldButtonLabel labelText={'Add'} />
                  </Button>
                </div>
              </Card>
              <div className="flex items-center justify-between mt-4">
                <Row gutter={24}>
                  <Col xs={12} md={24}>
                    <Form.Item label="" name={'search'}>
                      <Input.Search
                        placeholder="Search..."
                        onSearch={handleOnSearch}
                        enterButton
                        size="large"
                        suffix={<CloseCircleOutlined style={{ opacity: '0.8' }} onClick={handleOnClearSearch} />}
                      ></Input.Search>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col>
                    <Button
                      size="large"
                      block
                      type="primary"
                      onClick={() => {
                        setImportModalVisible(true);
                      }}
                    >
                      <BoldButtonLabel labelText="Bulk Upload" />
                      <UploadOutlined />
                    </Button>
                  </Col>
                </Row>
              </div>
              <section className="mt-4">
                {selectedRowKeys.length > 0 && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                    <Button type="primary" danger icon={<DeleteOutlined />} onClick={onDeleteSelected}>
                      Delete Selected
                    </Button>
                  </div>
                )}
                <Table
                  rowSelection={rowSelection}
                  loading={false}
                  pagination={{ ...pagination, position: ['bottomRight'] }}
                  bordered
                  dataSource={
                    promotionForm.apply_discount_on === 'PRODUCTS'
                      ? rewardSearchData?.length > 0
                        ? rewardSearchData
                        : rewardData
                      : categorySearchData?.length > 0
                      ? categorySearchData
                      : categoryData
                  }
                  columns={promotionForm.apply_discount_on === 'PRODUCTS' ? product_columns : category_columns}
                  scroll={{ x: 1000 }}
                  rowKey={(record: any) => {
                    if (promotionForm.apply_discount_on === 'PRODUCTS') {
                      return record?.item_id;
                    } else {
                      return record?.categoryId;
                    }
                  }}
                ></Table>
              </section>
            </div>
          </>
        )}
        {appliesTo === 'ORDER_TOTAL' && (
          <>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Threshold Selection"
                  name={'threshold_selection'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Select
                    allowClear
                    placeholder="Select"
                    size="large"
                    options={[
                      { label: 'None', value: 'NO_THRESHOLD' },
                      { label: 'MOV', value: 'MOV' },
                      { label: 'MOQ', value: 'MOQ' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.threshold_selection !== curValues.threshold_selection;
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const current_threshold_selection = getFieldValue('threshold_selection');
                  return (
                    current_threshold_selection === 'NO_THRESHOLD' && (
                      <Col xs={12} md={6}>
                        <Form.Item
                          label="Discount Value"
                          name="discount_value"
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            validationHelpers.getNumericValidatorForInput({})
                          ]}
                        >
                          <Input size="large" placeholder="Discount Value" />
                        </Form.Item>
                      </Col>
                    )
                  );
                }}
              </Form.Item>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Maximum Discount"
                  name={'max_discount'}
                  rules={[
                    { required: false, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Maximum Discount"
                    className="w-full"
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.threshold_selection !== curValues.threshold_selection ||
                  prevValues.max_limit_set !== curValues.max_limit_set
                );
              }}
            >
              {({ getFieldValue, setFieldsValue }) => {
                const thresholdSelection = getFieldValue('threshold_selection');
                const subtotalThreshold = getFieldValue('subtotal_treshold') || [];

                if (thresholdSelection && thresholdSelection !== 'NO_THRESHOLD' && subtotalThreshold.length === 0) {
                  setFieldsValue({
                    subtotal_treshold: [{ minimum_value: null, max_limit_set: false }]
                  });
                }

                let previousMaxValue = 0;
                if (subtotalThreshold?.length > 0) {
                  const lastItem = subtotalThreshold[subtotalThreshold.length - 1];
                  previousMaxValue = lastItem.maximum_value || 0;
                }

                return (
                  thresholdSelection &&
                  thresholdSelection !== 'NO_THRESHOLD' && (
                    <>
                      <Form.List name="subtotal_treshold">
                        {(fields, { add, remove }) => (
                          <>
                            {fields?.map(({ key, name, fieldKey, ...restField }, index) => {
                              const maxLimitSet = getFieldValue(['subtotal_treshold', name, 'max_limit_set']);
                              const isLastItem = index === fields.length - 1;
                              const currentPreviousMaxValue =
                                index > 0
                                  ? getFieldValue(['subtotal_treshold', fields[index - 1].name, 'maximum_value'])
                                  : 0;
                              return (
                                <div key={key} {...restField}>
                                  <Row gutter={12}>
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Minimum Value"
                                        name={[name, 'minimum_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          {
                                            validator: (_, value) => {
                                              if (value >= currentPreviousMaxValue) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject(
                                                new Error(
                                                  `Minimum Value must be greater than ${currentPreviousMaxValue}`
                                                )
                                              );
                                            }
                                          },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          })
                                        ]}
                                      >
                                        <InputNumber
                                          min={currentPreviousMaxValue}
                                          size="large"
                                          placeholder="Minimum Value"
                                          className="w-full"
                                          style={{ borderRadius: '0' }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {!maxLimitSet && (
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Maximum Value"
                                          name={[name, 'maximum_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            {
                                              validator: (_, value) => {
                                                const minValue = getFieldValue([
                                                  'subtotal_treshold',
                                                  name,
                                                  'minimum_value'
                                                ]);
                                                if (value >= minValue) {
                                                  return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                  new Error('Maximum Value must be greater than Minimum Value')
                                                );
                                              }
                                            },
                                            getFormattedNumeric2DecimalPointValidatorForInput({
                                              message:
                                                'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                            })
                                          ]}
                                        >
                                          <InputNumber
                                            min={1}
                                            size="large"
                                            placeholder="Maximum Value"
                                            className="w-full"
                                            style={{ borderRadius: '0' }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Discount Value"
                                        name={[name, 'discount_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          }),
                                          {
                                            validator: async (_: any, value: any) => {
                                              const discountType = getFieldValue('discount_type');
                                              if (discountType === 'PERCENTAGE' && value > 100) {
                                                return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                              }
                                              return Promise.resolve();
                                            }
                                          }
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          placeholder="Discount Value"
                                          // onBlur={formatToTwoDecimalPlaces}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {isLastItem && (
                                      <Col xs={12} md={3} className="mt-8">
                                        <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                          <Checkbox>Max Limit Set</Checkbox>
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {fields.length > 1 && (
                                      <Col xs={12} md={2}>
                                        <div className="mx-4 h-full flex justify-center items-center">
                                          <DeleteOutlined
                                            className="text-red-500 text-xl cursor-pointer"
                                            role="button"
                                            title="Remove"
                                            onClick={() => remove(name)}
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              );
                            })}
                            {/* <Row>
                                <Col> */}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add({ minimum_value: previousMaxValue, max_limit_set: false })}
                                className="mt-2"
                                disabled={fields.some(field =>
                                  getFieldValue(['subtotal_treshold', field.name, 'max_limit_set'])
                                )}
                              >
                                Add
                              </Button>
                            </Form.Item>
                            {/* </Col>
                              </Row> */}
                          </>
                        )}
                      </Form.List>
                    </>
                  )
                );
              }}
            </Form.Item>
          </>
        )}
      </>
    );
  };

  const BundleScreenRenderer = () => {
    const { discount_type } = promotionForm;

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const bundleRowSelection: TableRowSelection<any> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
    };

    const getVolumeColumn = () => {
      if (discount_type === 'SPL_PRICE') {
        return bundle_Product_Columns;
      }
      if (discount_type === 'PERCENTAGE' || discount_type === 'FIXED') {
        return bundle_Percent_Absolute_Columns;
      }
      return [];
    };

    return (
      <>
        {discount_type !== 'SPL_PRICE' && (
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Discount Value"
                name={'discount_value'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                    message: 'Enter up to 10 digits and 2 decimals'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Discount Value"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Discount"
                name={'max_discount'}
                rules={[
                  { required: false, message: "This field can't be empty" },
                  validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                    message: 'Enter up to 10 digits and 2 decimals'
                  })
                ]}
              >
                <InputNumber
                  size="large"
                  placeholder="Maximum Discount"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <div>
          <Card>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Item ID"
                  name={'item_id'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Input size="large" placeholder="Item Id" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Minimum Quantity"
                  name={'minimum_quantity'}
                  rules={[
                    { required: true, message: `This field can't be empty` },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const maxQuantity = getFieldValue('maximum_quantity');
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (value !== undefined && maxQuantity !== undefined && value > maxQuantity) {
                          return Promise.reject(new Error('Minimum Quantity cannot exceed Maximum Quantity'));
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <InputNumber
                    placeholder={'Minimum Quantity'}
                    className="w-full"
                    min={1}
                    size="large"
                    style={{ borderRadius: '0' }}
                  ></InputNumber>
                </Form.Item>
              </Col>
              {(discount_type === 'PERCENTAGE' || discount_type === 'FIXED') && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Maximum Quantity'}
                    name={'maximum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const minQuantity = getFieldValue('minimum_quantity');
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (value !== undefined && minQuantity !== undefined && value < minQuantity) {
                            return Promise.reject(new Error('Maximum Quantity cannot be less than Minimum Quantity'));
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Maximum Quantity'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>
              )}
              {discount_type === 'SPL_PRICE' && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Special Price"
                    name={'discount_value'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Special Price" />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <div className="mt-2">
          <Button type="primary" size="large" onClick={handleOnAddBundleDiscount}>
            <BoldButtonLabel labelText={'Add Product'} />
          </Button>
        </div>
        <div className="flex items-center justify-between mt-4">
          <Row gutter={24}>
            <Col xs={12} md={24}>
              <Form.Item label="" name={'search'}>
                <Input.Search placeholder="Search..." onSearch={handleOnSearch} enterButton size="large"></Input.Search>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col>
              <Button
                size="large"
                block
                type="primary"
                onClick={() => {
                  setImportModalVisible(true);
                }}
              >
                <BoldButtonLabel labelText="Bulk Upload" />
                <UploadOutlined />
              </Button>
            </Col>
          </Row>
        </div>
        <section className="mt-4">
          {selectedRowKeys.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
              <Button type="primary" danger icon={<DeleteOutlined />} onClick={onDeleteSelected}>
                Delete Selected
              </Button>
            </div>
          )}
          <Table
            rowSelection={bundleRowSelection}
            loading={false}
            pagination={{ ...pagination, position: ['bottomRight'] }}
            bordered
            dataSource={bundleDiscountSearchData.length > 0 ? bundleDiscountSearchData : bundleDiscountData}
            columns={getVolumeColumn()}
            scroll={{ x: 1000 }}
            rowKey={(record: any) => record?.item_id}
          ></Table>
        </section>
      </>
    );
  };

  const ShippingScreenRenderer = () => {
    const threshold_selection = Form.useWatch('threshold_selection', rewardForm);
    return (
      <>
        <div>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Threshold Selection"
                name={'threshold_selection'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Select
                  allowClear
                  placeholder="Select"
                  size="large"
                  options={[
                    { label: 'None', value: 'NO_THRESHOLD' },
                    { label: 'MOV', value: 'MOV' },
                    { label: 'MOQ', value: 'MOQ' }
                  ]}
                ></Select>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.threshold_selection !== curValues.threshold_selection;
              }}
            >
              {form => {
                const { threshold_selection } = form.getFieldsValue();
                return (
                  threshold_selection === 'NO_THRESHOLD' && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        name="discount_value"
                        label="Discount Value"
                        rules={[
                          { required: true, message: "This field can't be empty" },
                          getFormattedNumeric2DecimalPointValidatorForInput({
                            message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                          })
                        ]}
                      >
                        <InputNumber
                          min={1}
                          className="w-full"
                          size="large"
                          placeholder="Discount Value"
                          style={{ borderRadius: '0' }}
                        />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>

            <Col xs={12} md={6}>
              <Form.Item
                name="max_discount"
                label="Maximum Discount"
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  className="w-full"
                  size="large"
                  placeholder="Maximum Discount"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* ---------------------------------------- */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => prevValues.threshold_selection !== curValues.threshold_selection}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const threshold = getFieldValue('threshold_selection');
              const shipping_discount = getFieldValue('shipping_discount') || [];

              if (threshold === 'NO_THRESHOLD') {
                return null;
              }

              let previousMaxValue = 0;
              if (shipping_discount?.length > 0) {
                const lastItem = shipping_discount[shipping_discount.length - 1];
                previousMaxValue = lastItem.to_Quantity || 0;
              }

              return (
                <Form.List name="shipping_discount">
                  {(fields, { add, remove }) => (
                    <>
                      <Typography.Title level={4}>Shipping Discount:</Typography.Title>
                      {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                        const isLastField = index === fields.length - 1;
                        const maxLimitSetChecked = getFieldValue(['shipping_discount', name, 'max_limit_set']);
                        const currentPreviousMaxValue =
                          index > 0 ? getFieldValue(['shipping_discount', fields[index - 1].name, 'to_Quantity']) : 0;

                        return (
                          <div key={key} {...restField}>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Minimum Value"
                                  name={[name, 'from_Quantity']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: (_, value) =>
                                        value >= currentPreviousMaxValue
                                          ? Promise.resolve()
                                          : Promise.reject(
                                              new Error(`Minimum Value must be greater than ${currentPreviousMaxValue}`)
                                            )
                                    },
                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                      message:
                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                    })
                                  ]}
                                >
                                  <InputNumber
                                    min={currentPreviousMaxValue}
                                    size="large"
                                    placeholder="From Quantity"
                                    className="w-full"
                                  />
                                </Form.Item>
                              </Col>

                              {!maxLimitSetChecked && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Maximum Value"
                                    name={[name, 'to_Quantity']}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      {
                                        validator: (_, value) => {
                                          const minValue = getFieldValue(['shipping_discount', name, 'from_Quantity']);
                                          return value >= minValue
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                new Error('Maximum Value must be greater than Minimum Value')
                                              );
                                        }
                                      },
                                      getFormattedNumeric2DecimalPointValidatorForInput({
                                        message:
                                          'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                      })
                                    ]}
                                  >
                                    <InputNumber min={1} size="large" placeholder="To Quantity" className="w-full" />
                                  </Form.Item>
                                </Col>
                              )}

                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Discount Value"
                                  name={[name, 'discount_Value']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: async (_, value) => {
                                        const discountType = getFieldValue('discount_type');
                                        return discountType === 'PERCENTAGE' && value > 100
                                          ? Promise.reject(new Error('Percentage cannot exceed 100.'))
                                          : Promise.resolve();
                                      }
                                    },
                                    getFormattedNumeric2DecimalPointValidatorForInput({
                                      message:
                                        'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                    })
                                  ]}
                                >
                                  <InputNumber min={1} size="large" placeholder="Value" className="w-full" />
                                </Form.Item>
                              </Col>

                              {isLastField && (
                                <Col xs={12} md={3} className="mt-8">
                                  <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                    <Checkbox
                                      onChange={e => {
                                        const isChecked = e.target.checked;
                                        const updatedDiscounts = [...shipping_discount];
                                        updatedDiscounts[index].max_limit_set = isChecked;
                                        setFieldsValue({ shipping_discount: updatedDiscounts });
                                      }}
                                    >
                                      Max Limit Set
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                              )}

                              {fields.length > 1 && (
                                <Col xs={12} md={2}>
                                  <div className="mx-4 h-full flex justify-center items-center">
                                    <DeleteOutlined
                                      className="text-red-500 text-xl cursor-pointer"
                                      role="button"
                                      title="Remove"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add({ from_Quantity: previousMaxValue, max_limit_set: false })}
                          className="mt-2"
                          disabled={
                            fields.length > 0 &&
                            getFieldValue(['shipping_discount', fields[fields.length - 1].name, 'max_limit_set'])
                          }
                        >
                          Add Quantity
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              );
            }}
          </Form.Item>
          {/* ---------------------------------------- */}
        </div>
      </>
    );
  };

  const BxgyScreenProductRenderer = () => {
    const { discount_type } = promotionForm;

    const handleOnBxgyGetColumns = () => {
      if (discount_type === 'PERCENTAGE' || discount_type === 'FIXED') return bxgy_Get_items;
      if (discount_type === 'SPL_PRICE') return bxgy_Get_items_Special;
    };

    return (
      <>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Applies to Price"
              name={'applies_to_price'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                options={[
                  { label: 'None', value: 'NONE' },
                  { label: 'Lowest', value: 'LOWEST' },
                  { label: 'Highest', value: 'HIGHEST' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          {discount_type !== 'SPL_PRICE' && (
            <Col xs={12} md={6}>
              <Form.Item
                label="Discount Value"
                name={'discount_value'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Discount Value"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          )}
          {discount_type === 'PERCENTAGE' && (
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Discount"
                name={'max_discount'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Maximum Discount"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <div className="mb-2 bg-[#f0f8ff] p-8">
          <Typography.Title level={4}>Buy Item(s)</Typography.Title>
          <Row gutter={12} className="mt-4">
            <Col xs={12} md={6}>
              <Form.Item
                label="Selection Type For Items"
                name={'operator_selection_buy'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Select
                  placeholder="Select"
                  allowClear
                  size="large"
                  disabled={bxgyBuyItems.length > 0}
                  options={[
                    { label: 'Buy All Item(s)', value: 'AND' },
                    { label: 'Buy Any Item(s)', value: 'OR' }
                  ]}
                  onSelect={(item: any) => {
                    if (item === 'AND') {
                      rewardForm.setFieldsValue({
                        operator_selection_get: 'AND'
                      });
                    } else {
                      rewardForm.setFieldsValue({
                        operator_selection_get: 'OR'
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Item ID"
                name={'item_id_buy'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Item Id" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={'Minimum Quantity'}
                name={'minimum_quantity_buy'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                    message: 'Enter up to 10 digits and 2 decimals'
                  })
                ]}
              >
                <InputNumber
                  placeholder={'Minimum Quantity'}
                  className="w-full"
                  min={0}
                  size="large"
                  style={{ borderRadius: '0' }}
                ></InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} justify={'space-between'}>
            <Col xs={12} md={4}>
              <Button
                size="large"
                type="primary"
                block
                onClick={() => {
                  handleOnAddBuyItems();
                }}
              >
                <BoldButtonLabel labelText="Add Buy Item"></BoldButtonLabel>
              </Button>
            </Col>
            <Col xs={12} md={3}>
              <Button
                size="large"
                type="primary"
                block
                onClick={() => {
                  setImportModalVisible(true);
                }}
              >
                <BoldButtonLabel labelText="Bulk Upload"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <section className="mt-4">
            <Table
              // rowSelection={bundleRowSelection}
              loading={false}
              pagination={{ ...pagination, position: ['bottomRight'] }}
              bordered
              dataSource={bxgyBuyItems}
              columns={bxgy_Buy_items}
              scroll={{ x: 1000 }}
              rowKey={(record: any) => record?.item_id}
            ></Table>
          </section>
        </div>
        <div className="mb-2 bg-[#f0f8ff] p-8">
          <Typography.Title level={4}>Get Item(s)</Typography.Title>
          <Row gutter={12} className="mt-4">
            <Col xs={12} md={6}>
              <Form.Item
                label="Selection Type For Rewards"
                name={'operator_selection_get'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Select
                  placeholder="Select"
                  allowClear
                  size="large"
                  disabled={bxgyGetItems.length > 0}
                  options={[
                    { label: 'Get All Item(s)', value: 'AND' },
                    { label: 'Get Any Item(s)', value: 'OR' }
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Item ID"
                name={'item_id_get'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Item Id" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={'Minimum Quantity'}
                name={'minimum_quantity_get'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                    message: 'Enter up to 10 digits and 2 decimals'
                  })
                ]}
              >
                <InputNumber
                  placeholder={'Minimum Quantity'}
                  className="w-full"
                  min={0}
                  size="large"
                  style={{ borderRadius: '0' }}
                ></InputNumber>
              </Form.Item>
            </Col>
            {discount_type === 'SPL_PRICE' && (
              <Col xs={12} md={6}>
                <Form.Item
                  label={'Special Price'}
                  name={'discount_value_special'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    placeholder={'Special Price'}
                    className="w-full"
                    min={0}
                    size="large"
                    style={{ borderRadius: '0' }}
                  ></InputNumber>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={12} justify={'space-between'}>
            <Col xs={12} md={4}>
              <Button
                size="large"
                type="primary"
                block
                onClick={() => {
                  handleOnAddGetItems();
                }}
              >
                <BoldButtonLabel labelText="Add Get Item"></BoldButtonLabel>
              </Button>
            </Col>
            <Col xs={12} md={3}>
              <Button
                size="large"
                type="primary"
                block
                onClick={() => {
                  setImportModalGetBXGYVisible(true);
                }}
              >
                <BoldButtonLabel labelText="Bulk Upload"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <section className="mt-4">
            <Table
              // rowSelection={bundleRowSelection}
              loading={false}
              pagination={{ ...pagination, position: ['bottomRight'] }}
              bordered
              dataSource={bxgyGetItems}
              columns={handleOnBxgyGetColumns()}
              scroll={{ x: 1000 }}
              rowKey={(record: any) => record?.item_id}
            ></Table>
          </section>
        </div>
      </>
    );
  };

  const BxgyScreenCategoryRenderer = () => {
    const { discount_type } = promotionForm;

    return (
      <>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Applies to Price"
              name={'applies_to_price'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Select
                placeholder="Select"
                size="large"
                options={[
                  { label: 'None', value: 'NONE' },
                  { label: 'Lowest', value: 'LOWEST' },
                  { label: 'Highest', value: 'HIGHEST' }
                ]}
              ></Select>
            </Form.Item>
          </Col>
          {discount_type !== 'SPL_PRICE' && (
            <Col xs={12} md={6}>
              <Form.Item
                label="Discount Value"
                name={'discount_value'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Discount Value"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          )}
          {discount_type === 'PERCENTAGE' && (
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Discount"
                name={'max_discount'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Maximum Discount"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Card>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Line Items"
                name={'min_line_items'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Minimum Line Items"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Category"
                name={'category'}
                rules={[{ required: true, message: "This field can't be empty" }]}
              >
                <Input size="large" placeholder="Category" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Minimum Quantity"
                name={'minimum_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Minimum Quantity"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Quantity"
                name={'maximum_quantity'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  getFormattedNumeric2DecimalPointValidatorForInput({
                    message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Maximum Quantity"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} justify={'space-between'}>
            <Col xs={12} md={4}>
              <Button
                size="large"
                type="primary"
                block
                onClick={() => {
                  handleOnAddBXGYCategory();
                }}
              >
                <BoldButtonLabel labelText="Add New Category"></BoldButtonLabel>
              </Button>
            </Col>
            <Col xs={12} md={3}>
              <Button
                size="large"
                type="primary"
                block
                onClick={() => {
                  setImportModalVisible(true);
                }}
              >
                <BoldButtonLabel labelText="Bulk Upload"></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <section className="mt-4">
            <Table
              // rowSelection={bundleRowSelection}
              loading={false}
              pagination={{ ...pagination, position: ['bottomRight'] }}
              bordered
              dataSource={bxgyCategory}
              columns={bxgy_category_columns}
              scroll={{ x: 1000 }}
              rowKey={(record: any) => record?.item_id}
            ></Table>
          </section>
        </Card>
      </>
    );
  };

  const BxgyScreenRenderer = () => {
    const { apply_discount_on } = promotionForm;
    return (
      <>
        {apply_discount_on === 'PRODUCTS' && BxgyScreenProductRenderer()}
        {apply_discount_on === 'CATEGORY' && BxgyScreenCategoryRenderer()}
      </>
    );
  };

  const VolumeProductRenderer = () => {
    const { discount_type } = promotionForm;
    return (
      <Card>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Item ID"
              name={'item_id'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Input size="large" placeholder="Item Id" />
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.thresholds_available !== currentValues.thresholds_available
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue('thresholds_available') ? null : (
                <>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={
                        discount_type === 'PERCENTAGE' || discount_type === 'FIXED' ? 'Minimum Quantity' : 'Quantity'
                      }
                      name={'minimum_quantity'}
                      rules={[
                        { required: true, message: `This field can't be empty` },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const maxQuantity = getFieldValue('maximum_quantity');
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value !== undefined && maxQuantity !== undefined && value > maxQuantity) {
                              return Promise.reject(new Error('Minimum Quantity cannot exceed Maximum Quantity'));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={
                          discount_type === 'PERCENTAGE' || discount_type === 'FIXED' ? 'Minimum Quantity' : 'Quantity'
                        }
                        className="w-full"
                        min={1}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                  {(discount_type === 'PERCENTAGE' || discount_type === 'FIXED') && (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={'Maximum Quantity'}
                          name={'maximum_quantity'}
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const minQuantity = getFieldValue('minimum_quantity');
                                if (!value) {
                                  return Promise.resolve();
                                }
                                if (value !== undefined && minQuantity !== undefined && value < minQuantity) {
                                  return Promise.reject(
                                    new Error('Maximum Quantity cannot be less than Minimum Quantity')
                                  );
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <InputNumber
                            placeholder={'Maximum Quantity'}
                            className="w-full"
                            min={0}
                            size="large"
                            style={{ borderRadius: '0' }}
                          ></InputNumber>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={'Discount Value'}
                          name={'discount_value'}
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            {
                              validator: async (_, value) => {
                                const { discount_type } = promotionForm;
                                return discount_type === 'PERCENTAGE' && value > 100
                                  ? Promise.reject(new Error('Percentage cannot exceed 100.'))
                                  : Promise.resolve();
                              }
                            },
                            getFormattedNumeric2DecimalPointValidatorForInput({
                              message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                            })
                          ]}
                        >
                          <InputNumber
                            placeholder={'Discount Value'}
                            className="w-full"
                            min={0}
                            size="large"
                            style={{ borderRadius: '0' }}
                          ></InputNumber>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {discount_type === 'SPL_PRICE' && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Special Price"
                        name={'discount_value'}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input size="large" placeholder="Special Price" />
                      </Form.Item>
                    </Col>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Row>
        <Row gutter={12}>
          {discount_type === 'PERCENTAGE' && (
            <>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.max_discount_checkbox !== currentValues.max_discount_checkbox
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue('max_discount_checkbox') ? null : (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Maximum Discount Value"
                        name="max_discount"
                        rules={[
                          { required: true, message: "This field can't be empty" },
                          validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                            message: 'Enter up to 10 digits and 2 decimals'
                          })
                        ]}
                      >
                        <InputNumber
                          placeholder="Maximum Discount Value"
                          className="w-full"
                          min={0}
                          size="large"
                          style={{ borderRadius: '0' }}
                        />
                      </Form.Item>
                    </Col>
                  );
                }}
              </Form.Item>
              <Col>
                <Form.Item valuePropName="checked" name={'max_discount_checkbox'}>
                  <Checkbox>Set Max Discount Value</Checkbox>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={12}>
          <Col>
            <Form.Item valuePropName="checked" name={'thresholds_available'}>
              <Checkbox>Volume Treshold In Range?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.thresholds_available !== currentValues.thresholds_available ||
            JSON.stringify(prevValues.thresholds) !== JSON.stringify(currentValues.thresholds)
          }
        >
          {({ getFieldValue, setFieldsValue }) => {
            const thresholdActive = getFieldValue('thresholds_available');
            const thresholds = getFieldValue('thresholds') || [];

            if (!thresholdActive) return null;

            return (
              <Form.List name="thresholds">
                {(fields, { add, remove }) => {
                  let previousMaxValue =
                    thresholds.length > 0 ? thresholds[thresholds.length - 1]?.maximum_value || 1 : 1;

                  return (
                    <div className="mb-2 bg-[#f0f8ff] p-8">
                      <Typography.Title level={4}>Add Volume Threshold</Typography.Title>
                      {fields.map(({ key, name, ...restField }, index) => {
                        const currentPreviousMaxValue =
                          index > 0 ? getFieldValue(['thresholds', fields[index - 1].name, 'maximum_value']) : 1;

                        const maxLimitSetChecked = getFieldValue(['thresholds', name, 'max_limit_set']);
                        const isLastField = index === fields.length - 1;

                        return (
                          <div key={key} {...restField}>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Minimum Value"
                                  name={[name, 'minimum_value']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: (_, value) =>
                                        value >= currentPreviousMaxValue
                                          ? Promise.resolve()
                                          : Promise.reject(
                                              new Error(`Minimum Value must be >= ${currentPreviousMaxValue}`)
                                            )
                                    }
                                  ]}
                                >
                                  <InputNumber
                                    min={currentPreviousMaxValue}
                                    size="large"
                                    placeholder="Minimum Value"
                                    className="w-full"
                                    style={{ borderRadius: '0' }}
                                  />
                                </Form.Item>
                              </Col>
                              {!maxLimitSetChecked && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Maximum Value"
                                    name={[name, 'maximum_value']}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      {
                                        validator: (_, value) => {
                                          const minValue = getFieldValue(['thresholds', name, 'minimum_value']);
                                          return value >= minValue
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Maximum Value must be > Minimum Value'));
                                        }
                                      }
                                    ]}
                                  >
                                    <InputNumber
                                      size="large"
                                      placeholder="Maximum Value"
                                      className="w-full"
                                      style={{ borderRadius: '0' }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Discount Value"
                                  name={[name, 'discount_value']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: (_, value) => {
                                        const discountType = getFieldValue('discount_type');
                                        return discountType === 'PERCENTAGE' && value > 100
                                          ? Promise.reject(new Error('Percentage cannot exceed 100.'))
                                          : Promise.resolve();
                                      }
                                    }
                                  ]}
                                >
                                  <InputNumber
                                    min={1}
                                    size="large"
                                    placeholder="Value"
                                    className="w-full"
                                    style={{ borderRadius: '0' }}
                                  />
                                </Form.Item>
                              </Col>
                              {isLastField && (
                                <Col xs={12} md={3} className="mt-8">
                                  <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                    <Checkbox
                                      onChange={e => {
                                        const updatedThresholds = thresholds.map((item: any, idx: any) =>
                                          idx === index ? { ...item, max_limit_set: e.target.checked } : item
                                        );
                                        setFieldsValue({ thresholds: updatedThresholds });
                                      }}
                                    >
                                      Max Limit Set
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                              )}

                              {fields.length > 1 && (
                                <Col xs={12} md={2}>
                                  <DeleteOutlined
                                    className="text-red-500 text-xl cursor-pointer"
                                    onClick={() => remove(name)}
                                  />
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={async () => {
                            const values = rewardForm.getFieldsValue();
                            const fieldsToValidate: (string | (string | number)[])[] = [];
                            const treshold_list = values.thresholds || [];
                            treshold_list.forEach((_: any, index: number) => {
                              fieldsToValidate.push(['thresholds', index, 'minimum_value']);
                              fieldsToValidate.push(['thresholds', index, 'maximum_value']);
                              fieldsToValidate.push(['thresholds', index, 'discount_value']);
                            });
                            await rewardForm.validateFields(fieldsToValidate);
                            add({ minimum_value: previousMaxValue });
                          }}
                          className="mt-2"
                        >
                          Add Threshold
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            );
          }}
        </Form.Item>
        <Row gutter={12} justify={'space-between'}>
          <Col xs={12} md={4}>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => {
                handleOnAddVolume();
              }}
            >
              <BoldButtonLabel labelText="Add Volumes"></BoldButtonLabel>
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => {
                setImportModalVisible(true);
              }}
            >
              <BoldButtonLabel labelText="Bulk Upload"></BoldButtonLabel>
            </Button>
          </Col>
        </Row>
        <section className="mt-4">
          <Table
            bordered
            expandable={{
              expandedRowRender,
              rowExpandable: record => record?.thresholds
            }}
            className="mt-4"
            dataSource={volumeProductData}
            columns={getColoumnsForVoumeProducts()}
            scroll={{ x: 1000 }}
            pagination={{ ...pagination, position: ['bottomRight'] }}
            rowKey={(record: any) => record.item_id}
          />
        </section>
      </Card>
    );
  };

  const VolumeCategoryRenderer = () => {
    const { discount_type } = promotionForm;
    return (
      <Card>
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item
              label="Select Category"
              name={'category'}
              rules={[{ required: true, message: "This field can't be empty" }]}
            >
              <Input size="large" placeholder="Select Category" />
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.thresholds_available !== currentValues.thresholds_available
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue('thresholds_available') ? null : (
                <>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={
                        discount_type === 'PERCENTAGE' || discount_type === 'FIXED' ? 'Minimum Quantity' : 'Quantity'
                      }
                      name={'minimum_quantity'}
                      rules={[
                        { required: true, message: `This field can't be empty` },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const maxQuantity = getFieldValue('maximum_quantity');
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value !== undefined && maxQuantity !== undefined && value > maxQuantity) {
                              return Promise.reject(new Error('Minimum Quantity cannot exceed Maximum Quantity'));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={
                          discount_type === 'PERCENTAGE' || discount_type === 'FIXED' ? 'Minimum Quantity' : 'Quantity'
                        }
                        className="w-full"
                        min={1}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                  {(discount_type === 'PERCENTAGE' || discount_type === 'FIXED') && (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={'Maximum Quantity'}
                          name={'maximum_quantity'}
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const minQuantity = getFieldValue('minimum_quantity');
                                if (!value) {
                                  return Promise.resolve();
                                }
                                if (value !== undefined && minQuantity !== undefined && value < minQuantity) {
                                  return Promise.reject(
                                    new Error('Maximum Quantity cannot be less than Minimum Quantity')
                                  );
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <InputNumber
                            placeholder={'Maximum Quantity'}
                            className="w-full"
                            min={0}
                            size="large"
                            style={{ borderRadius: '0' }}
                          ></InputNumber>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={'Discount Value'}
                          name={'discount_value'}
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            {
                              validator: async (_, value) => {
                                const { discount_type } = promotionForm;
                                return discount_type === 'PERCENTAGE' && value > 100
                                  ? Promise.reject(new Error('Percentage cannot exceed 100.'))
                                  : Promise.resolve();
                              }
                            },
                            getFormattedNumeric2DecimalPointValidatorForInput({
                              message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                            })
                          ]}
                        >
                          <InputNumber
                            placeholder={'Discount Value'}
                            className="w-full"
                            min={0}
                            size="large"
                            style={{ borderRadius: '0' }}
                          ></InputNumber>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {discount_type === 'SPL_PRICE' && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Special Price"
                        name={'discount_value'}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input size="large" placeholder="Special Price" />
                      </Form.Item>
                    </Col>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Row>
        <Row gutter={12}>
          {discount_type === 'PERCENTAGE' && (
            <>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.max_discount_checkbox !== currentValues.max_discount_checkbox
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue('max_discount_checkbox') ? null : (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Maximum Discount Value"
                        name="max_discount"
                        rules={[
                          { required: true, message: "This field can't be empty" },
                          validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                            message: 'Enter up to 10 digits and 2 decimals'
                          })
                        ]}
                      >
                        <InputNumber
                          placeholder="Maximum Discount Value"
                          className="w-full"
                          min={0}
                          size="large"
                          style={{ borderRadius: '0' }}
                        />
                      </Form.Item>
                    </Col>
                  );
                }}
              </Form.Item>
              <Col>
                <Form.Item valuePropName="checked" name={'max_discount_checkbox'}>
                  <Checkbox>Set Max Discount Value</Checkbox>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={12}>
          <Col>
            <Form.Item valuePropName="checked" name={'thresholds_available'}>
              <Checkbox>Volume Treshold In Range?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.thresholds_available !== currentValues.thresholds_available ||
            JSON.stringify(prevValues.thresholds) !== JSON.stringify(currentValues.thresholds)
          }
        >
          {({ getFieldValue, setFieldsValue }) => {
            const thresholdActive = getFieldValue('thresholds_available');
            const thresholds = getFieldValue('thresholds') || [];

            if (!thresholdActive) return null;

            return (
              <Form.List name="thresholds">
                {(fields, { add, remove }) => {
                  let previousMaxValue =
                    thresholds.length > 0 ? thresholds[thresholds.length - 1]?.maximum_value || 1 : 1;

                  return (
                    <div className="mb-2 bg-[#f0f8ff] p-8">
                      <Typography.Title level={4}>Add Volume Threshold</Typography.Title>
                      {fields.map(({ key, name, ...restField }, index) => {
                        const currentPreviousMaxValue =
                          index > 0 ? getFieldValue(['thresholds', fields[index - 1].name, 'maximum_value']) : 1;

                        const maxLimitSetChecked = getFieldValue(['thresholds', name, 'max_limit_set']);
                        const isLastField = index === fields.length - 1;

                        return (
                          <div key={key} {...restField}>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Minimum Value"
                                  name={[name, 'minimum_value']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: (_, value) =>
                                        value >= currentPreviousMaxValue
                                          ? Promise.resolve()
                                          : Promise.reject(
                                              new Error(`Minimum Value must be >= ${currentPreviousMaxValue}`)
                                            )
                                    }
                                  ]}
                                >
                                  <InputNumber
                                    min={currentPreviousMaxValue}
                                    size="large"
                                    placeholder="Minimum Value"
                                    className="w-full"
                                    style={{ borderRadius: '0' }}
                                  />
                                </Form.Item>
                              </Col>
                              {!maxLimitSetChecked && (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label="Maximum Value"
                                    name={[name, 'maximum_value']}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      {
                                        validator: (_, value) => {
                                          const minValue = getFieldValue(['thresholds', name, 'minimum_value']);
                                          return value >= minValue
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Maximum Value must be > Minimum Value'));
                                        }
                                      }
                                    ]}
                                  >
                                    <InputNumber
                                      size="large"
                                      placeholder="Maximum Value"
                                      className="w-full"
                                      style={{ borderRadius: '0' }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col xs={12} md={6}>
                                <Form.Item
                                  label="Discount Value"
                                  name={[name, 'discount_value']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    {
                                      validator: (_, value) => {
                                        const discountType = getFieldValue('discount_type');
                                        return discountType === 'PERCENTAGE' && value > 100
                                          ? Promise.reject(new Error('Percentage cannot exceed 100.'))
                                          : Promise.resolve();
                                      }
                                    }
                                  ]}
                                >
                                  <InputNumber
                                    min={1}
                                    size="large"
                                    placeholder="Value"
                                    className="w-full"
                                    style={{ borderRadius: '0' }}
                                  />
                                </Form.Item>
                              </Col>
                              {isLastField && (
                                <Col xs={12} md={3} className="mt-8">
                                  <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                    <Checkbox
                                      onChange={e => {
                                        const updatedThresholds = thresholds.map((item: any, idx: any) =>
                                          idx === index ? { ...item, max_limit_set: e.target.checked } : item
                                        );
                                        setFieldsValue({ thresholds: updatedThresholds });
                                      }}
                                    >
                                      Max Limit Set
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                              )}

                              {fields.length > 1 && (
                                <Col xs={12} md={2}>
                                  <DeleteOutlined
                                    className="text-red-500 text-xl cursor-pointer"
                                    onClick={() => remove(name)}
                                  />
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={async () => {
                            const values = rewardForm.getFieldsValue();
                            const fieldsToValidate: (string | (string | number)[])[] = [];
                            const treshold_list = values.thresholds || [];
                            treshold_list.forEach((_: any, index: number) => {
                              fieldsToValidate.push(['thresholds', index, 'minimum_value']);
                              fieldsToValidate.push(['thresholds', index, 'maximum_value']);
                              fieldsToValidate.push(['thresholds', index, 'discount_value']);
                            });
                            await rewardForm.validateFields(fieldsToValidate);
                            add({ minimum_value: previousMaxValue });
                          }}
                          className="mt-2"
                        >
                          Add Threshold
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            );
          }}
        </Form.Item>
        <Row gutter={12} justify={'space-between'}>
          <Col xs={12} md={4}>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => {
                handleOnAddVolume();
              }}
            >
              <BoldButtonLabel labelText="Add Volume"></BoldButtonLabel>
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => {
                setImportModalVisible(true);
              }}
            >
              <BoldButtonLabel labelText="Bulk Upload"></BoldButtonLabel>
            </Button>
          </Col>
        </Row>
        <section className="mt-4">
          <Table
            bordered
            expandable={{
              expandedRowRender,
              rowExpandable: record => record?.thresholds
            }}
            className="mt-4"
            dataSource={volumeCategorytData}
            columns={getColoumnsForVoumeCategory()}
            scroll={{ x: 1000 }}
            pagination={{ ...pagination, position: ['bottomRight'] }}
            rowKey={(record: any) => record.category}
          />
        </section>
      </Card>
    );
  };

  const expandedRowRender = (data: any) => {
    return (
      <div className="p-4">
        <Table
          className="mt-2"
          bordered
          columns={ExpandVolumeRowColumnPercentage}
          dataSource={data?.thresholds || []}
          pagination={false}
        />
      </div>
    );
    // }
  };

  const VolumeScreenRenderer = () => {
    const { apply_discount_on } = promotionForm;
    return (
      <>
        {apply_discount_on === 'PRODUCTS' && VolumeProductRenderer()}
        {apply_discount_on === 'CATEGORY' && VolumeCategoryRenderer()}
      </>
    );
  };

  const SaveHandler = () => {
    let creationBodyData = bdyData;
    let rewardData = getNewReward();

    if (promotionForm.appliesTo !== 'ORDER_TOTAL' && promotionForm.apply_discount_on !== 'PRODUCTS') {
      const { excluded_brand, excluded_products } = rewardForm.getFieldsValue();
      const exclusions = [];
      if (!_.isEmpty(excluded_products)) {
        exclusions.push({
          operation: 'EXCLUDE',
          type: 'PRODUCTS',
          value_type: 'SKU_ID',
          values: excluded_products || []
        });
      }
      if (!_.isEmpty(excluded_brand)) {
        exclusions.push({
          operation: 'EXCLUDE',
          type: 'BRAND',
          value_type: 'SKU_ID',
          values: excluded_brand || []
        });
      }
      // let data = structuredClone(creationBodyData);
      creationBodyData?.criterias?.criteria_list?.push(...exclusions);
    }
    let combinedBodyData = {
      ...creationBodyData,
      reward_details: rewardData
        ? Object.values(rewardData).map(reward => ({
            applies_to_price: reward.applies_to_price,
            apply_discount_on: reward.apply_discount_on,
            discount_type: reward.discount_type,
            product_reward: reward.product_reward,
            threshold_selection: reward.threshold_selection,
            thresholds: reward.thresholds,
            discount_value: reward.discount_value,
            max_discount: reward.max_discount
          }))
        : []
    };

    if (!_.isEmpty(promotionResponse)) {
      combinedBodyData?.reward_details?.forEach((reward: any) => {
        reward.discount_id = promotionResponse?.discount[0]?.discount_id;
      });
      handleUpdate(combinedBodyData);
    } else {
      handleOnCreate(combinedBodyData);
    }
  };

  const getDisableSBTFields = () => {
    if (promotionForm.status === 'OPEN') {
      return false;
    }
    return !creation && (rewardData.length > 0 || categoryData.length > 0);
  };

  return (
    <div>
      <Card>
        <Form form={rewardForm} scrollToFirstError layout="vertical">
          {/* UI */}
          {promotionForm.appliesTo !== 'ORDER_TOTAL' && promotionForm.apply_discount_on !== 'PRODUCTS' && (
            <div>
              <Typography.Title level={5}>Exclusion Condition</Typography.Title>
              <Row gutter={12}>
                <Col xs={12} md={8}>
                  <Form.Item label="Excluded Products" name={'excluded_products'}>
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchProductDropdownOptions(searchTerm);
                      }}
                      filterOption={false}
                      placeholder="Excluded products"
                      notFoundContent={productDropdownFetching ? <Spin size="small" /> : <>Search Item</>}
                      options={productDropdownOptions}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Item label="Exclude Brand" name={'excluded_brand'}>
                    <Select placeholder="Select Or Enter Brand" allowClear mode="tags" size="large" options={[]} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          {promotionForm.promotionType === 'SUB_TTL_DIS' && SubTotalScreenRenderer()}
          {promotionForm.promotionType === 'BUNDLE_DISC' && BundleScreenRenderer()}
          {promotionForm.promotionType === 'SHIPPING_DISC' && ShippingScreenRenderer()}
          {promotionForm.promotionType === 'BXGY' && BxgyScreenRenderer()}
          {promotionForm.promotionType === 'VOLUME_DISC' && VolumeScreenRenderer()}
          {/* UI */}
          {/* buttons----------- */}
          <div>
            <Row gutter={[12, 12]} className="mt-4">
              <Col xs={12} md={6}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    let creationBodyData = bdyData;
                    let combinedBodyData = goBackSaver(creationBodyData);
                    handleOnGoBack(0, combinedBodyData);
                  }}
                  block
                >
                  <BoldButtonLabel labelText="Go Back"></BoldButtonLabel>
                </Button>
              </Col>
              {promotionForm.promotionType === 'BXGY' && (
                <Col xs={12} md={6}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={() => {
                      handleOnUpdateBxgyProduct();
                    }}
                  >
                    <BoldButtonLabel labelText="Update BXGY"></BoldButtonLabel>
                  </Button>
                </Col>
              )}
              {promotionForm.promotionType === 'BUNDLE_DISC' && (
                <Col xs={12} md={6}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    disabled={bundleDiscountData.length < 1}
                    onClick={() => {
                      handleOnUpdateBundlePromo();
                    }}
                  >
                    <BoldButtonLabel labelText="Update Productssss"></BoldButtonLabel>
                  </Button>
                </Col>
              )}
              {promotionForm.promotionType === 'SHIPPING_DISC' && (
                <Col xs={12} md={6}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={() => {
                      handleOnShippingDiscount();
                    }}
                  >
                    <BoldButtonLabel labelText="Update"></BoldButtonLabel>
                  </Button>
                </Col>
              )}
              {promotionForm.promotionType === 'SUB_TTL_DIS' && promotionForm.apply_discount_on === 'PRODUCTS' && (
                <Col xs={12} md={6}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    disabled={rewardData.length < 1}
                    onClick={() => {
                      handleOnUpdatePromo();
                    }}
                  >
                    <BoldButtonLabel labelText="Save"></BoldButtonLabel>
                  </Button>
                </Col>
              )}
              {promotionForm.promotionType === 'SUB_TTL_DIS' && promotionForm.apply_discount_on === 'CATEGORY' && (
                <Col xs={12} md={6}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    disabled={categoryData.length < 1}
                    onClick={() => {
                      handleOnUpdateCategoryPromo();
                    }}
                  >
                    <BoldButtonLabel labelText="Save"></BoldButtonLabel>
                  </Button>
                </Col>
              )}
              {promotionForm.appliesTo === 'ORDER_TOTAL' && (
                <Col xs={12} md={6}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={() => {
                      handleOnUpdateTreshold();
                    }}
                  >
                    <BoldButtonLabel labelText="Update"></BoldButtonLabel>
                  </Button>
                </Col>
              )}
              {promotionForm.promotionType === 'VOLUME_DISC' && (
                <Col xs={12} md={6}>
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={() => {
                      handleOnUpdateVolumePromo();
                    }}
                  >
                    <BoldButtonLabel labelText="Save Volume"></BoldButtonLabel>
                  </Button>
                </Col>
              )}
              {/* <Col xs={12} md={6}>
              <Button size="large" type="primary" block onClick={scheduleDiscount}>
                <BoldButtonLabel labelText="Schedule Discount"></BoldButtonLabel>
              </Button>
            </Col> */}
            </Row>
          </div>
          {/* buttons----------- */}
        </Form>

        {/* MODAL------ */}
        <div>
          <Modal
            centered
            open={importModalVisible}
            onCancel={() => {
              closePostCodeModal();
            }}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Import Data</Typography.Title>
              </div>
            }
          >
            <section className="flex flex-col justify-center">
              <Button type="link" className="mb-4">
                Download Sample Template
              </Button>
              <Dragger
                beforeUpload={file => {
                  setFileList([file]);
                  return false;
                }}
                onRemove={() => {
                  setFileList([]);
                }}
                fileList={fileList}
                name="file"
                accept={'.csv,.xlsx'}
              >
                <p>
                  <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
                </p>
                <p>Click or Drag Customers Excel File to upload</p>
              </Dragger>
              <Button
                disabled={_.isEmpty(fileList)}
                block
                size="large"
                type="primary"
                onClick={handleOnUpload}
                className="mt-4"
              >
                <BoldButtonLabel labelText="Upload" />
              </Button>
            </section>
          </Modal>
          <Modal
            centered
            open={importModalGetBXGYVisible}
            onCancel={() => {
              closePostCodeModal();
            }}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Import Data</Typography.Title>
              </div>
            }
          >
            <section className="flex flex-col justify-center">
              <Button type="link" className="mb-4">
                Download Sample Template
              </Button>
              <Dragger
                beforeUpload={file => {
                  setFileList([file]);
                  return false;
                }}
                onRemove={() => {
                  setFileList([]);
                }}
                fileList={fileList}
                name="file"
                accept={'.csv,.xlsx'}
              >
                <p>
                  <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
                </p>
                <p>Click or Drag Customers Excel File to upload</p>
              </Dragger>
              <Button
                disabled={_.isEmpty(fileList)}
                block
                size="large"
                type="primary"
                onClick={handleOnUploadGet}
                className="mt-4"
              >
                <BoldButtonLabel labelText="Upload" />
              </Button>
            </section>
          </Modal>
          <Modal
            centered
            open={editRewardModal}
            onCancel={() => {
              setEditRewardModal(false);
            }}
            width={1000}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Edit Reward</Typography.Title>
              </div>
            }
          >
            <Form
              form={updateRewardForm}
              scrollToFirstError
              layout="vertical"
              onFinish={() => {
                handleOnUpdateItem();
              }}
            >
              <Row gutter={12}>
                {promotionForm.apply_discount_on === 'PRODUCTS' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Item ID"
                      name={'item_id'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Item Id" />
                    </Form.Item>
                  </Col>
                )}
                {promotionForm.apply_discount_on === 'CATEGORY' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Category ID"
                      name={'categoryId'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Category ID" disabled={true} />
                    </Form.Item>
                  </Col>
                )}
                {promotionForm.apply_discount_on === 'CATEGORY' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Category"
                      name={'category'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Category" />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Minimum Quantity'}
                    name={'minimum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                        message: 'Enter up to 10 digits and 2 decimals'
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Minimum Quantity'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>{' '}
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Maximum Quantity'}
                    name={'maximum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                        message: 'Enter up to 10 digits and 2 decimals'
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Maximum Quantity'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>{' '}
              </Row>
              <div className="mt-4">
                <Button htmlType="submit" type="primary" size="large">
                  <BoldButtonLabel labelText={'Update'} />
                </Button>
              </div>
            </Form>
          </Modal>
          <Modal
            centered
            open={bundleEditModal}
            onCancel={() => {
              setBundleEditModal(false);
            }}
            width={1000}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Edit Reward</Typography.Title>
              </div>
            }
          >
            <Form
              form={bundleRewardForm}
              scrollToFirstError
              layout="vertical"
              onFinish={() => {
                handleOnEditVolumePromo();
              }}
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Item ID"
                    name={'item_id'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Item Id" disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Minimum Quantity'}
                    name={'minimum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                        message: 'Enter up to 10 digits and 2 decimals'
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Minimum Quantity'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>{' '}
                {promotionForm.discount_type !== 'SPL_PRICE' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Maximum Quantity'}
                      name={'maximum_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Maximum Quantity'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                )}
                {promotionForm.discount_type === 'SPL_PRICE' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Special Price'}
                      name={'discount_value'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Special Price'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <div className="mt-4">
                <Button htmlType="submit" type="primary" size="large">
                  <BoldButtonLabel labelText={'Update'} />
                </Button>
              </div>
            </Form>
          </Modal>
          <Modal
            centered
            open={bundleEditModal}
            onCancel={() => {
              setBundleEditModal(false);
            }}
            width={1000}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Edit Reward</Typography.Title>
              </div>
            }
          >
            <Form
              form={bundleRewardForm}
              scrollToFirstError
              layout="vertical"
              onFinish={() => {
                handleOnEditVolumePromo();
              }}
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Item ID"
                    name={'item_id'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Item Id" disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Minimum Quantity'}
                    name={'minimum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                        message: 'Enter up to 10 digits and 2 decimals'
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Minimum Quantity'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>{' '}
                {promotionForm.discount_type !== 'SPL_PRICE' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Maximum Quantity'}
                      name={'maximum_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Maximum Quantity'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                )}
                {promotionForm.discount_type === 'SPL_PRICE' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Special Price'}
                      name={'discount_value'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Special Price'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <div className="mt-4">
                <Button htmlType="submit" type="primary" size="large">
                  <BoldButtonLabel labelText={'Update'} />
                </Button>
              </div>
            </Form>
          </Modal>
          <Modal
            centered
            open={bxgyeditModal}
            onCancel={() => {
              setBxgyEditModal(false);
            }}
            width={1000}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Edit Reward</Typography.Title>
              </div>
            }
          >
            <Form
              form={bxgyRewardForm}
              scrollToFirstError
              layout="vertical"
              onFinish={() => {
                handleOnBxgyProductEdit();
              }}
            >
              <Card>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Item ID"
                      name={'item_id'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Item Id" disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Minimum Quantity'}
                      name={'minimum_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Minimum Quantity'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>{' '}
                </Row>
                <div className="mt-4">
                  <Button htmlType="submit" type="primary" size="large">
                    <BoldButtonLabel labelText={'Update'} />
                  </Button>
                </div>
              </Card>
            </Form>
          </Modal>
          <Modal
            centered
            open={bxgyeditModal}
            onCancel={() => {
              setBxgyEditModal(false);
            }}
            width={1000}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Edit Reward</Typography.Title>
              </div>
            }
          >
            <Form
              form={bxgyRewardForm}
              scrollToFirstError
              layout="vertical"
              onFinish={() => {
                handleOnBxgyProductGetEdit();
              }}
            >
              <Card>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Item ID"
                      name={'item_id'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Item Id" disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Minimum Quantity'}
                      name={'minimum_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Minimum Quantity'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>{' '}
                </Row>
                <div className="mt-4">
                  <Button htmlType="submit" type="primary" size="large">
                    <BoldButtonLabel labelText={'Update'} />
                  </Button>
                </div>
              </Card>
            </Form>
          </Modal>
          <Modal
            centered
            open={bxgyeditCategoryModal}
            onCancel={() => {
              setBxgyEditCategoryModal(false);
            }}
            width={1000}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Edit Reward</Typography.Title>
              </div>
            }
          >
            <Form
              form={bxgyRewardForm}
              scrollToFirstError
              layout="vertical"
              onFinish={() => {
                handleOnBxgyCategoryEdit();
              }}
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Category Number"
                    name={'index'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Category Number" disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Category"
                    name={'category'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Category" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Minimum Quantity"
                    name={'minimum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      getFormattedNumeric2DecimalPointValidatorForInput({
                        message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                      })
                    ]}
                  >
                    <InputNumber
                      min={1}
                      size="large"
                      placeholder="Minimum Quantity"
                      className="w-full"
                      style={{ borderRadius: '0' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Maximum Quantity"
                    name={'maximum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      getFormattedNumeric2DecimalPointValidatorForInput({
                        message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                      })
                    ]}
                  >
                    <InputNumber
                      min={1}
                      size="large"
                      placeholder="Maximum Quantity"
                      className="w-full"
                      style={{ borderRadius: '0' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="mt-4">
                <Button htmlType="submit" type="primary" size="large">
                  <BoldButtonLabel labelText={'Update'} />
                </Button>
              </div>
            </Form>
          </Modal>
          <Modal
            centered
            open={volumeEditModal}
            onCancel={() => {
              setVolumeEditModal(false);
            }}
            width={1000}
            footer={false}
            title={
              <div className="flex justify-between gap-4">
                <Typography.Title level={4}>Edit Reward</Typography.Title>
              </div>
            }
          >
            <Form
              form={volumeRewardForm}
              scrollToFirstError
              layout="vertical"
              onFinish={() => {
                handleOnVolumeCategoryEdit();
              }}
            >
              <Row gutter={12}>
                {promotionForm.apply_discount_on === 'CATEGORY' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Category Number"
                      name={'item_id'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Category Number" disabled={true} />
                    </Form.Item>
                  </Col>
                )}
                {promotionForm.apply_discount_on === 'PRODUCTS' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Item ID"
                      name={'item_id'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Item ID" disabled={true} />
                    </Form.Item>
                  </Col>
                )}
                {promotionForm.apply_discount_on === 'CATEGORY' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Category"
                      name={'category'}
                      rules={[{ required: true, message: "This field can't be empty" }]}
                    >
                      <Input size="large" placeholder="Category" disabled={true} />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Minimum Quantity"
                    name={'minimum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      getFormattedNumeric2DecimalPointValidatorForInput({
                        message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                      })
                    ]}
                  >
                    <InputNumber
                      min={1}
                      size="large"
                      placeholder="Quantity"
                      className="w-full"
                      style={{ borderRadius: '0' }}
                    />
                  </Form.Item>
                </Col>
                {promotionForm.discount_type !== 'SPL_PRICE' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Maximum Quantity"
                      name={'maximum_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        getFormattedNumeric2DecimalPointValidatorForInput({
                          message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                        })
                      ]}
                    >
                      <InputNumber
                        min={1}
                        size="large"
                        placeholder="Maximum Quantity"
                        className="w-full"
                        style={{ borderRadius: '0' }}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={12} md={6}>
                  <Form.Item
                    label={promotionForm.discount_type === 'SPL_PRICE' ? 'Special Price' : 'Discount Value'}
                    name={'discount_value'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      getFormattedNumeric2DecimalPointValidatorForInput({
                        message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                      })
                    ]}
                  >
                    <InputNumber
                      min={1}
                      size="large"
                      placeholder="Special Price"
                      className="w-full"
                      style={{ borderRadius: '0' }}
                    />
                  </Form.Item>
                </Col>
                {promotionForm.discount_type === 'PERCENTAGE' && (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label="Maximum Discount"
                      name={'max_discount'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        getFormattedNumeric2DecimalPointValidatorForInput({
                          message: 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                        })
                      ]}
                    >
                      <InputNumber
                        min={1}
                        size="large"
                        placeholder="Maximum Discount"
                        className="w-full"
                        style={{ borderRadius: '0' }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {volumeRewardForm.getFieldValue('thresholds') && (
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    JSON.stringify(prevValues.thresholds) !== JSON.stringify(currentValues.thresholds)
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const thresholds = getFieldValue('thresholds') || [];
                    const maxLimitSetChecked = thresholds.some((item: any) => item?.max_limit_set);

                    return (
                      <Form.List name="thresholds">
                        {(fields, { add, remove }) => {
                          let thresholdData = getFieldValue('thresholds') || [];
                          let previousMaxValue =
                            thresholdData.length > 0 ? thresholdData[thresholdData.length - 1]?.maximum_value || 1 : 1;

                          return (
                            <div className="mb-2 bg-[#f0f8ff] p-8">
                              <Typography.Title level={4}>Edit Volume Threshold</Typography.Title>
                              {fields.map(({ key, name, ...restField }, index) => {
                                const currentPreviousMaxValue =
                                  index > 0
                                    ? getFieldValue(['thresholds', fields[index - 1].name, 'maximum_value'])
                                    : 1;
                                const maxLimitSetCheckedRow = getFieldValue(['thresholds', name, 'max_limit_set']);
                                const isLastField = index === fields.length - 1;

                                return (
                                  <div key={key}>
                                    <Row gutter={12}>
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Minimum Value"
                                          name={[name, 'minimum_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            {
                                              validator: (_, value) =>
                                                value >= currentPreviousMaxValue
                                                  ? Promise.resolve()
                                                  : Promise.reject(
                                                      new Error(`Minimum Value must be >= ${currentPreviousMaxValue}`)
                                                    )
                                            }
                                          ]}
                                        >
                                          <InputNumber
                                            min={currentPreviousMaxValue}
                                            size="large"
                                            placeholder="Minimum Value"
                                            className="w-full"
                                            style={{ borderRadius: '0' }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {!maxLimitSetCheckedRow && (
                                        <Col xs={12} md={6}>
                                          <Form.Item
                                            label="Maximum Value"
                                            name={[name, 'maximum_value']}
                                            rules={[
                                              { required: true, message: "This field can't be empty" },
                                              {
                                                validator: (_, value) => {
                                                  const minValue = getFieldValue(['thresholds', name, 'minimum_value']);
                                                  return value >= minValue
                                                    ? Promise.resolve()
                                                    : Promise.reject(
                                                        new Error('Maximum Value must be > Minimum Value')
                                                      );
                                                }
                                              }
                                            ]}
                                          >
                                            <InputNumber
                                              size="large"
                                              placeholder="Maximum Value"
                                              className="w-full"
                                              style={{ borderRadius: '0' }}
                                            />
                                          </Form.Item>
                                        </Col>
                                      )}
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Discount Value"
                                          name={[name, 'discount_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            {
                                              validator: (_, value) => {
                                                const discountType = getFieldValue('discount_type');
                                                return discountType === 'PERCENTAGE' && value > 100
                                                  ? Promise.reject(new Error('Percentage cannot exceed 100.'))
                                                  : Promise.resolve();
                                              }
                                            }
                                          ]}
                                        >
                                          <InputNumber
                                            min={1}
                                            size="large"
                                            placeholder="Value"
                                            className="w-full"
                                            style={{ borderRadius: '0' }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {isLastField && (
                                        <Col xs={12} md={3} className="mt-8">
                                          <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                            <Checkbox
                                              onChange={e => {
                                                const updatedThresholds = thresholds.map((item: any, idx: any) =>
                                                  idx === index ? { ...item, max_limit_set: e.target.checked } : item
                                                );
                                                setFieldsValue({ thresholds: updatedThresholds });
                                              }}
                                            >
                                              Max Limit Set
                                            </Checkbox>
                                          </Form.Item>
                                        </Col>
                                      )}
                                      {fields.length > 1 && (
                                        <Col xs={12} md={2}>
                                          <DeleteOutlined
                                            className="text-red-500 text-xl cursor-pointer"
                                            onClick={() => remove(name)}
                                          />
                                        </Col>
                                      )}
                                    </Row>
                                  </div>
                                );
                              })}
                              <Form.Item>
                                <Button
                                  type="primary"
                                  disabled={maxLimitSetChecked}
                                  onClick={async () => {
                                    const values = volumeRewardForm.getFieldsValue();
                                    const fieldsToValidate: (string | (string | number)[])[] = [];
                                    const treshold_list = values.thresholds || [];
                                    treshold_list.forEach((_: any, index: number) => {
                                      fieldsToValidate.push(['thresholds', index, 'minimum_value']);
                                      fieldsToValidate.push(['thresholds', index, 'maximum_value']);
                                      fieldsToValidate.push(['thresholds', index, 'discount_value']);
                                    });
                                    await volumeRewardForm.validateFields(fieldsToValidate);
                                    add({ minimum_value: previousMaxValue });
                                  }}
                                  className="mt-2"
                                >
                                  Add Threshold
                                </Button>
                              </Form.Item>
                            </div>
                          );
                        }}
                      </Form.List>
                    );
                  }}
                </Form.Item>
              )}

              <div className="mt-4">
                <Button htmlType="submit" type="primary" size="large">
                  <BoldButtonLabel labelText={'Update'} />
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
        {/* MODAL------ */}
      </Card>
    </div>
  );
};

export default RewardForm;
