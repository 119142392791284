import React from 'react';
import _ from '../../../../helpers/lodash';
import { productService } from '../../services';

const cache: Record<string, any> = {};

export const useProduct = () => {
  const [productDropDownFetching, setProductDropDownFetching] = React.useState(false);
  const [productDropDownOptions, setProductDropDownOptions] = React.useState<{ label: string; value: string }[]>([]);

  const fetchProductDropdownOptions = async (searchQuery: string, limit?: number) => {
    if (cache[searchQuery]) {
      const transformedResponse = transformResponse(cache[searchQuery]);
      setProductDropDownOptions(() => {
        return [...transformedResponse];
      });
    } else {
      setProductDropDownFetching(true);
      const { data, errors } = await productService.getCategories(searchQuery, limit);
      cache[searchQuery] = data;
      if (_.isEmpty(errors)) {
        const transformedResponse = transformResponse(data);
        setProductDropDownOptions(() => {
          return [...transformedResponse];
        });
      }
      setProductDropDownFetching(false);
    }
  };

  const transformResponse = (data: any) => {
    const dnndata = data?.categories || [];
    return (dnndata || []).map(({ category }: { category: string }) => {
      return { label: `${category}`, value: category };
    });
  };

  const debouncedFetchProductDropdownOptions = _.debounce(fetchProductDropdownOptions, 700);

  return {
    debouncedFetchProductDropdownOptions,
    productDropDownFetching,
    productDropDownOptions
  };
};
