import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Table, TableColumnsType, Tag, Typography } from 'antd';
import { IOperator, IOperatorListResponse } from '../../types/operator.types';
import CustomPagination from '../../components/custom-pagination';
import _ from '../../helpers/lodash';
import { operatorService } from '../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { useLoader } from '../../stores/use-loader';
import ActionButton from '../../components/ActionButton';

interface INodeListProps { }

const OperatorList: React.FunctionComponent<INodeListProps> = props => {
  const [operatorFilterForm] = Form.useForm();
  const navigate = useNavigate();
  const [operatorListResponse, setOperatorListResponse] = React.useState({} as IOperatorListResponse);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>({
    pageSize: 10,
    currentPage: 1
  });
  const backUrl = window.location?.pathname + `?${searchParams.toString()}`;
  const nextPageParams = new URLSearchParams({ goBackTo: backUrl });

  const handleOperatorSearch = async (searchTerm: string) => {
    const params: any = { operatorName: searchTerm, currentPage: 1 };
    setSearchParams(params);
    fetchOperatorList(1, searchTerm);
  };

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const columns: TableColumnsType<IOperator> = [
    {
      title: 'Operator Name',
      render(value, record, index) {
        return <>{record.operator_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Type',
      render(value, record, index) {
        return <>{record.operator_type}</>;
      },
      align: 'center'
    },
    {
      title: 'Is Active?',
      render(value, record, index) {
        return (
          <>
            {record.is_active ? (
              <Tag color="success" className="w-16 text-center">
                Active
              </Tag>
            ) : (
              <Tag color="error" className="w-16 text-center">
                Inactive
              </Tag>
            )}
          </>
        );
      },
      align: 'center'
    },
    {
      title: 'Operator Status',
      render(value, record, index) {
        return <>{record.status}</>;
      },
      align: 'center'
    },

    {
      title: '',
      render(value, record, index) {
        return (
          <section className="flex gap-2 justify-center items-center">
            <ActionButton
              action="CREATE_NEW_VERSION"
              title="Edit"
              onClick={() => navigate(`/operators/${record.operator_id}?${nextPageParams.toString()}`)}
            />

            {!record.is_active ? (
              <ActionButton
                action="ACTIVATE"
                title="Activate"
                onClick={() => handleActiveToggle({ is_active: true, operatorId: record.operator_id, operatorName: record?.operator_name })}
              ></ActionButton>
            ) : (
              <ActionButton
                action="DEACTIVATE"
                title="Deactivate"
                onClick={() => handleActiveToggle({ is_active: false, operatorId: record.operator_id, operatorName: record?.operator_name })}
              ></ActionButton>
            )}

            {!(record.status === 'PUBLISHED') ? (
              <Button
                type="primary"
                onClick={() => handleStatusToggle({ operatorId: record.operator_id, publish: true, operatorName: record?.operator_name })}
                className="w-32 text-center"
              >
                <BoldButtonLabel labelText="Publish" />
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => handleStatusToggle({ unpublish: true, operatorId: record.operator_id, operatorName: record?.operator_name })}
                className="w-32 text-center"
              >
                <BoldButtonLabel labelText="Unpublish" />
              </Button>
            )}
          </section>
        );
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    const currentPage = parseInt(searchParams.get('currentPage') || '1') || undefined;
    const searchTerm = searchParams.get('operatorName') || undefined;

    operatorFilterForm.setFieldsValue({ searchTerm });
    fetchOperatorList(currentPage, searchTerm);
  }, [pageControl]);

  const fetchOperatorList = async (currentPage = 1, operatorName?: string) => {
    setLoading(true);
    const { data, errors } = await operatorService.getOperatorList(currentPage, operatorName, pageControl.pageSize);

    if (_.isEmpty(errors)) {
      setOperatorListResponse(data);
    } else displayErrorNotifications(errors);

    setLoading(false);
  };

  const handlePageChange = async (currentPage: number) => {
    await fetchOperatorList(currentPage);
  };

  const handleActiveToggle = async ({
    is_active,
    operatorId,
    operatorName
  }: {
    is_active: boolean;
    operatorId: string;
    operatorName: string;
  }) => {
    setLoading(true);
    const { errors } = await operatorService.changeActivationStatus(operatorId, is_active);

    if (_.isEmpty(errors)) {
      const action = is_active ? 'activated' : 'deactivated';
      displaySuccessNotification({ message: `${operatorName} - ${action} successfully` });
      fetchOperatorList();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };


  const handleStatusToggle = async ({
    operatorId,
    publish = false,
    unpublish = false,
    operatorName
  }: {
    operatorId: string;
    publish?: boolean;
    unpublish?: boolean;
    operatorName: string;
  }) => {
    setLoading(true);
    const { errors } = await operatorService.changeOperatorStatus(operatorId, publish, unpublish);

    if (_.isEmpty(errors)) {
      const action = publish ? 'published' : 'unpublished';
      displaySuccessNotification({ message: `${operatorName} - ${action} successfully` });
      fetchOperatorList();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };


  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="pt-">
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Operators
              </Typography.Title>
            </Col>
          </Row>

          <Form form={operatorFilterForm}>
            <Row justify={'space-between'}>
              <Col xs={24} md={8} xl={6}>
                <Form.Item name={'searchTerm'}>
                  <Input.Search
                    onSearch={handleOperatorSearch}
                    placeholder="Search Operator"
                    size="large"
                    enterButton
                  />
                </Form.Item>
              </Col>

              <Col>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => navigate(`/operators/create?${nextPageParams.toString()}`)}
                  block
                >
                  <BoldButtonLabel labelText="Create New Operator" />
                </Button>
              </Col>
            </Row>
          </Form>
          {/* {!_.isEmpty(operatorListResponse.operators) && (
            <div className="flex justify-end mt-2">
              <CustomPagination
                currentPage={currentPage}
                totalPages={operatorListResponse.total_pages}
                handleNext={handlePageChange}
                handlePageChange={handlePageChange}
                handlePrevious={handlePageChange}
              />
            </div>
          )} */}
          <Table
            loading={false}
            pagination={{
              current: pageControl?.currentPage,
              total: operatorListResponse.total_pages * pageControl?.pageSize || 0,
              pageSize: pageControl?.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['1', '10', '20', '50', '100'],

              onChange: (currentPage, pageSize) =>
                setPageControl({
                  currentPage,
                  pageSize
                })
            }}
            bordered
            className="mt-4"
            dataSource={operatorListResponse.operators}
            columns={columns}
            scroll={{ x: 1000 }}
          ></Table>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default OperatorList;
